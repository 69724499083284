import Type from "lib/entity/type"
import Data from "lib/entity/data"
import { $keyOrder } from "lib/entity/symbols"

export const fieldsAll = (context, parent) => {
    const t = Type.getType(context, "map")
    const parentType = Type.getKeyType(parent, context, t)
    return parentType?.[$keyOrder].map(key => {
        const field = `${parent}.${key}`
        const fieldType = Type.getKeyType(field, context, t)
        const value = Data.get(context, field)
        const typeName = Type.realType(fieldType)
        return {
            fieldName: key,
            field,
            fieldType,
            typeName,
            value,
        }
    })
}
export const fieldsRest = (context, parent) => {
    const t = Type.getType(context, "map")
    const parentType = Type.getKeyType(parent, context, t)
    return parentType?.[$keyOrder]
        .filter(key => parentType.keys[key]?.keyType === "dynamic")
        .map(key => {
            const field = `${parent}.${key}`
            const fieldType = Type.getKeyType(field, context, t)
            const value = Data.get(context, field)
            const typeName = Type.realType(fieldType)
            return {
                fieldName: key,
                field,
                fieldType,
                typeName,
                value,
            }
        })
}
