import React from "react"
import useAttrs from "ui/hooks/useAttrs"
import { useLocalState } from "ui/hooks/useLocalState"

import renderers from "ui/renderers"

const Machine = attrs => {
    const domRef = React.useRef()
    const { info } = attrs
    const { Image, Fields, Icon } = renderers
    const [state, dispatch] = useLocalState(
        { _e: { open: { _type: "bool" } } },
        attrs.state,
        attrs.dispatch
    )

    const htmlAttrs = useAttrs(attrs)
    const handler_1_0_0_onClick = React.useCallback(() => {
        dispatch({ type: "CHANGE", field: "open", value: !state?.open })
    }, [state])
    return (
        <section
            ref={domRef}
            {...htmlAttrs}
            elem-machine=""
            className="machine"
            section-state={state?.open ? "open" : "closed"}
            id={"section-" + attrs?._index}
        >
            <header>
                <Image
                    info={info}
                    state={state}
                    dispatch={dispatch}
                    src={attrs?.symbol?.[0]}
                    inline="1"
                />
                <div>
                    <Fields
                        info={info}
                        state={state}
                        dispatch={dispatch}
                        field="attrs.function"
                        attrs={attrs}
                        isContent={true}
                    />
                </div>
            </header>
            <div className="inner">
                <div className="ref-opener-outer">
                    <div className="ref-opener" onClick={handler_1_0_0_onClick}>
                        {!state?.open && <Icon icon="filled/add" />}
                        {state?.open && <Icon icon="filled/minus" />}
                    </div>
                </div>
                <div className="content">
                    <div className="content-inner">
                        <div className="title">
                            <h2>
                                <Fields
                                    info={info}
                                    state={state}
                                    dispatch={dispatch}
                                    field="attrs.title"
                                    attrs={attrs}
                                    isContent={true}
                                />
                            </h2>
                            <h3>
                                <Fields
                                    info={info}
                                    state={state}
                                    dispatch={dispatch}
                                    field="attrs.subtitle"
                                    attrs={attrs}
                                    isContent={true}
                                />
                            </h3>
                        </div>
                        <Image
                            info={info}
                            state={state}
                            dispatch={dispatch}
                            src={attrs?.thumb?.[0]}
                            className="thumb"
                        />
                        {!state?.open && (
                            <div className="teaser">
                                <Fields
                                    info={info}
                                    state={state}
                                    dispatch={dispatch}
                                    field="attrs.teaser"
                                    attrs={attrs}
                                    isContent={true}
                                />
                            </div>
                        )}
                        {state?.open && (
                            <div>
                                <Fields
                                    info={info}
                                    state={state}
                                    dispatch={dispatch}
                                    field="attrs.content"
                                    attrs={attrs}
                                    isContent={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Machine
/*
{
  "layout": {
    "data": {
      "name": "section",
      "attr": {
        "className": "machine",
        "_e": {
          "className": {
            "is": "exprValExt"
          },
          "section-state": {
            "1": {
              "_type": "op"
            },
            "_type": "op",
            "is": "exprValExt"
          },
          "id": {
            "2": {
              "_type": "op"
            },
            "_type": "op",
            "is": "exprValExt"
          }
        },
        "section-state": [
          "if",
          [
            "f",
            "state.open"
          ],
          "open",
          "closed"
        ],
        "id": [
          "+",
          "section-",
          [
            "get",
            "attrs",
            "_index"
          ]
        ]
      }
    },
    "children": [
      {
        "data": {
          "name": "header"
        },
        "children": [
          {
            "data": {
              "name": "Image",
              "attr": {
                "src": [
                  "get",
                  "attrs",
                  "symbol.0"
                ],
                "_e": {
                  "src": {
                    "_type": "op",
                    "is": "exprValExt"
                  }
                },
                "inline": "1"
              }
            }
          },
          {
            "data": {
              "name": "div",
              "attr": {
                "content": [
                  "get",
                  "attrs",
                  "function"
                ],
                "_e": {
                  "content": {
                    "_type": "op"
                  }
                }
              }
            }
          }
        ]
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "inner"
          }
        },
        "children": [
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "ref-opener-outer"
              }
            },
            "children": [
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "className": "ref-opener",
                    "onClick": [
                      "setState",
                      "open",
                      [
                        "!",
                        [
                          "f",
                          "state.open"
                        ]
                      ]
                    ],
                    "_e": {
                      "onClick": {
                        "2": {
                          "1": {
                            "_type": "op"
                          },
                          "_type": "op"
                        },
                        "_type": "action"
                      }
                    }
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "icon"
                        }
                      },
                      "name": "filled/add",
                      "attr": {
                        "if": [
                          "!",
                          [
                            "f",
                            "state.open"
                          ]
                        ],
                        "_e": {
                          "if": {
                            "1": {
                              "_type": "op"
                            },
                            "_type": "op"
                          }
                        }
                      }
                    }
                  },
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "icon"
                        }
                      },
                      "name": "filled/minus",
                      "attr": {
                        "if": [
                          "f",
                          "state.open"
                        ],
                        "_e": {
                          "if": {
                            "_type": "op"
                          }
                        }
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "content"
              }
            },
            "children": [
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "className": "content-inner"
                  }
                },
                "children": [
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "className": "title"
                      }
                    },
                    "children": [
                      {
                        "data": {
                          "name": "h2",
                          "attr": {
                            "content": [
                              "get",
                              "attrs",
                              "title"
                            ],
                            "_e": {
                              "content": {
                                "_type": "op"
                              }
                            }
                          }
                        }
                      },
                      {
                        "data": {
                          "name": "h3",
                          "attr": {
                            "content": [
                              "get",
                              "attrs",
                              "subtitle"
                            ],
                            "_e": {
                              "content": {
                                "_type": "op"
                              }
                            }
                          }
                        }
                      }
                    ]
                  },
                  {
                    "data": {
                      "name": "Image",
                      "attr": {
                        "src": [
                          "get",
                          "attrs",
                          "thumb.0"
                        ],
                        "_e": {
                          "src": {
                            "_type": "op",
                            "is": "exprValExt"
                          },
                          "className": {
                            "is": "exprValExt"
                          }
                        },
                        "className": "thumb"
                      }
                    }
                  },
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "content": [
                          "get",
                          "attrs",
                          "teaser"
                        ],
                        "_e": {
                          "content": {
                            "_type": "op"
                          },
                          "if": {
                            "1": {
                              "_type": "op"
                            },
                            "_type": "op"
                          }
                        },
                        "className": "teaser",
                        "if": [
                          "!",
                          [
                            "f",
                            "state.open"
                          ]
                        ]
                      }
                    }
                  },
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "content": [
                          "get",
                          "attrs",
                          "content"
                        ],
                        "_e": {
                          "content": {
                            "_type": "op",
                            "is": "exprValExt"
                          },
                          "if": {
                            "_type": "op"
                          }
                        },
                        "if": [
                          "f",
                          "state.open"
                        ]
                      }
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "context": {
    "state": {
      "_e": {
        "open": {
          "_type": "bool"
        }
      }
    }
  }
}
*/
