import axios from "axios"
import { C } from "lib"
//import localforage from "localforage"
//import types from "lib/entity/base"
//import { db } from "conf/db"

let _id = 0
let cbs = {}
export const triggerUpdates = updates => {
    const ts = Math.floor(Date.now())
    Object.values(cbs).forEach(cb => cb(ts, updates))
}
export const registerForUpdates = cb => {
    _id++
    cbs[_id] = cb
    return _id
}
export const unregisterForUpdates = id => {
    delete cbs[id]
}
/*
const initIndexes = (storeName, indexes) => {
    if (indexes) {
        indexes.forEach(idx => {
            if (!window._db[storeName].idx[idx]) window._db[storeName].idx[idx] = {}
        })
    }
}
const updateIndexes = (storeName, indexes, value) => {
    if (indexes) {
        indexes.forEach(idx => {
            const config = db[storeName][idx]
            if (config.filter) {
                const ret = Object.keys(config.filter).reduce((acc, field) => {
                    if (!acc) return acc
                    return value[field] === config.filter[field]
                }, true)
                if (!ret) return
            }
            const field = config.key
            if (typeof field === "string") {
                if (typeof value[field] !== "undefined")
                    window._db[storeName].idx[idx][value[field]] = value._id
                return
            }
            field.forEach(f => {
                if (typeof value[f] !== "undefined")
                    window._db[storeName].idx[idx][value[f]] = value._id
            })
        })
    }
}
*/
export const updateDb = async () => {
    const instance = axios.create({ baseURL: C.BASE })
    try {
        const data = await instance.get("/db.json")
        window._db = data.data
        triggerUpdates()
        //return {}
        /*
        //const collections = db.collections //Object.keys(data.data)
        let updates = {}
        for (const storeName of db.collections) {
            if (!window._db[storeName]) window._db[storeName] = { data: {}, idx: {} }
            const indexes = Object.keys(db[storeName])
            initIndexes(storeName, indexes)
            for (const value of data.data[storeName]) {
                const key = value._id
                const old = window._db[storeName].data[key]
                if (!old || old?._updated !== value._updated) {
                    window._db[storeName].data[key] = value
                    if (!updates[storeName]) updates[storeName] = []
                    updateIndexes(storeName, indexes, value)
                    updates[storeName].push(key)
                }
            }
        }
        return updates
        */
    } catch (e) {
        console.log(e)
    }
    //return {}
}
/*
export const updateDb = async () => {
    if (!window._db) window._db = {}
    //if (!window._idx) window._idx = {}
    const name = "db"
    let updates = {}
    if (C.isLocal) {
        updates = await updateDbLocal()
    } else {
        const collections = Object.keys(db)
        for (const storeName of collections) {
            const store = localforage.createInstance({ name, storeName })
            if (!window._db[storeName]) window._db[storeName] = { data: {}, idx: {} }

            const indexes = Object.keys(db[storeName])
            initIndexes(storeName, indexes)
            await store.iterate((value, key) => {
                const old = window._db[storeName].data[key]
                if (!old || old?._updated !== value._updated) {
                    window._db[storeName].data[key] = value
                    if (!updates[storeName]) updates[storeName] = []
                    updateIndexes(storeName, indexes, value)
                    updates[storeName].push(key)
                }
            })
        }
    }
    triggerUpdates(updates)
}
*/
