import React from "react"
import useAttrs from "ui/hooks/useAttrs"

import renderers from "ui/renderers"

const Html = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { HTML } = renderers

    const htmlAttrs = useAttrs(attrs)
    return (
        <div ref={domRef} {...htmlAttrs} elem-html="" type-html="">
            <HTML info={info} state={state} dispatch={dispatch} />
        </div>
    )
}
export default Html
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "HTML"
        }
      }
    ]
  }
}
*/
