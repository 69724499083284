import React from "react"
import { fieldsRest } from "ui/utilFields"
import useAttrs from "ui/hooks/useAttrs"

import renderers from "ui/renderers"

const Block = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { Fields } = renderers

    const htmlAttrs = useAttrs(attrs)
    return (
        <div
            ref={domRef}
            {...htmlAttrs}
            elem-block=""
            block-empty={(fieldsRest(info.context, "e") ?? []).length === 0 ? "yes" : "no"}
            type-block=""
        >
            <Fields
                info={info}
                state={state}
                dispatch={dispatch}
                type="_rest_"
                parent="e"
                attrs={attrs}
                isContent={undefined}
            />
        </div>
    )
}
export default Block
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "block-empty": [
          "if",
          [
            "=",
            [
              "len",
              [
                "f",
                "e._rest_"
              ]
            ],
            0
          ],
          "yes",
          "no"
        ],
        "_e": {
          "block-empty": {
            "1": {
              "1": {
                "1": {
                  "_type": "op"
                },
                "_type": "op"
              },
              "2": {
                "_type": "int"
              },
              "_type": "op"
            },
            "_type": "op",
            "is": "exprValExt"
          }
        }
      }
    },
    "children": [
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e._rest_"
          ]
        }
      }
    ]
  }
}
*/
