import { useState, useRef, useCallback, useEffect } from "react"
import { C, isBrowser } from "lib"

const cssPath = `${C.BASE}/css/site`
const loadCSS = () => {
    const links = Array.from(document.getElementsByTagName("link"))
    if (!links) return
    for (let link of links) {
        if (!link || !link.getAttribute) continue
        const rel = link.getAttribute("rel")
        const as = link.getAttribute("as")
        //console.log(rel, as, cssPath, link.href)
        if (
            (rel === "stylesheet" || (rel === "preload" && as === "style")) &&
            link.href.indexOf(cssPath) >= 0
        ) {
            //console.log("enable")
            link.setAttribute("rel", "stylesheet")
            link.setAttribute("media", "screen")
            link.removeAttribute("disabled")
            return
        }
    }
    /*const head = document.head
    const link = document.createElement("link")

    link.type = "text/css"
    link.rel = "stylesheet"
    link.href = `${C.BASE}/css/site.css`
    head.appendChild(link)*/
}
//console.log(isHydrating())
//if (isHydrating()) {
//console.log("isbrowser", isBrowser)
if (isBrowser) {
    requestAnimationFrame(() => {
        requestAnimationFrame(loadCSS)
    })
}

//import throttle from "lodash.throttle"
//import fastdom from "fastdom"
const callbacks = {}
export const screen = {}
if (isBrowser) {
    window.screenState = screen
}
//let screenClasses = null
const mq = W => {
    if (W <= 400) return "xss"
    if (W <= 600) return "xs"
    if (W <= 900) return "sm"
    if (W <= 1200) return "md"
    if (W <= 1600) return "lg"
    return "xl"
}
const screenRatio = (w, h) => {
    if (h === 0) return "landscape"

    const ratio = w / h
    if (w < h) {
        if (ratio > 0.65) {
            return "4x3"
        } else {
            return "16x9"
        }
    } else {
        if (ratio < 1.5) {
            return "4x3"
        } else {
            return "16x9"
        }
    }
}
const screenOrientation = (W, H) => (W > H ? "landscape" : "portrait")
/*
const initScreen = () => {
    if (typeof window === "undefined") return { W: 0, H: 0 }
    const W = window.innerWidth
    const H = window.innerHeight
    return { W, H, MQ: mq(W), O: screenOrientation(W, H), RATIO: screenRatio(W, H) }
}*/
const update = () => {
    screen.running = false
    const W = window.innerWidth
    const H = window.innerHeight
    //console.log(W, H)
    screen.state = { W, H, MQ: mq(W), O: screenOrientation(W, H), RATIO: screenRatio(W, H) }
    Object.keys(callbacks).forEach(key => {
        if (callbacks[key]) callbacks[key](screen.state)
    })
}
const handleResize = () => {
    if (!screen.running) {
        screen.running = true
        requestAnimationFrame(update)
    }
}
export const screenDefault = { W: 0, H: 0, MQ: "xss", O: "portrait", RATIO: "16x9" }
if (!screen.init) {
    screen.init = true
    if (typeof window === "undefined") {
        screen.state = screenDefault
    } else {
        const W = window.innerWidth
        const H = window.innerHeight
        screen.state = { W, H, MQ: mq(W), O: screenOrientation(W, H), RATIO: screenRatio(W, H) }
        /*const screenClasses = [
            screen.state.MQ,
            screen.state.O,
            screen.state.W < 400 || screen.state.H < 400 ? "mobile" : "not-mobile",
        ]*/
        //document.body.classList.add(...screenClasses)
        //document.body.style.setProperty("--screen-ratio", screen.state.W / screen.state.H)
        window.addEventListener("resize", handleResize)
    }
}
let key = 0
const subscribe = cb => {
    callbacks[key] = cb
    key++
    return key - 1
}
const unsubscribe = key => {
    delete callbacks[key]
}
const useScreen = (cb, active = true) => {
    const [, triggerRender] = useState(0)

    //const screenState = useRef()
    const hydrating = useRef(typeof window !== "undefined" && window.isHydrating)
    const wasActive = useRef(false)
    const isActive = useRef()

    const cbRef = useRef()

    const handleResize = useCallback(() => {
        if (cbRef.current)
            cbRef.current(
                screen.state.W,
                screen.state.H,
                screen.state.MQ,
                screen.state.O,
                screen.state.RATIO
            )
        else {
            triggerRender(state => (state + 1) % 100)
        }
    }, [])
    cbRef.current = cb
    if (active && !wasActive.current) {
        //screen.current = initScreen()
        if (cbRef.current)
            cbRef.current(
                screen.state.W,
                screen.state.H,
                screen.state.MQ,
                screen.state.O,
                screen.state.RATIO
            )
    }
    wasActive.current = active
    useEffect(() => {
        if (!hydrating.current) return
        hydrating.current = false
        triggerRender(state => (state + 1) % 100)
    }, [])
    useEffect(() => {
        if (!active) return

        const key = subscribe(handleResize)
        isActive.current = true
        return () => {
            if (isActive.current) {
                unsubscribe(key)
                isActive.current = false
            }
        }
    }, [active, handleResize])
    if (hydrating.current) return screenDefault
    if (active && !cbRef.current) return screen.state
    return screenDefault
}
export default useScreen
