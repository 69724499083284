import Type from "lib/entity/type"
import elements from "elements"
import renderers from "./renderers"
import { capitalize } from "./util"
import { $lineage } from "lib/entity/symbols"

const getElement = typeInfo => {
    if (!typeInfo) return [undefined, undefined]
    if (typeInfo.renderer) {
        //console.log(typeInfo.renderer)
        const elementName = typeInfo.renderer
        const element = renderers[elementName] ?? elements[elementName]
        if (element) {
            return [elementName, element]
        }
    }
    const elementName = capitalize(Type.realType(typeInfo))
    const element = renderers[elementName] ?? elements[elementName]
    //console.log(elementName, element)
    if (element) {
        return [elementName, element]
    }
    return typeInfo?.[$lineage]?.reduce(
        (acc, typeName) => {
            if (acc[1]) return acc
            const elementName = capitalize(typeName)
            const element = renderers[elementName] ?? elements[elementName]
            //console.log(elementName, element)
            if (element) {
                return [elementName, element]
            }
            return acc
        },
        [undefined, undefined]
    )
}
export default getElement
