import React from "react"
import useAttrs from "ui/hooks/useAttrs"
import { useLocalState } from "ui/hooks/useLocalState"

import renderers from "ui/renderers"

const Menu = attrs => {
    const domRef = React.useRef()
    const { info } = attrs
    const { Icon, Link } = renderers
    const [state, dispatch] = useLocalState({ status: "closed" }, attrs.state, attrs.dispatch)

    const htmlAttrs = useAttrs(attrs)
    const handler_0_onClick = React.useCallback(() => {
        dispatch({
            type: "CHANGE",
            field: "status",
            value: state?.status === "open" ? "closed" : "open",
        })
    }, [state])
    const handler_1_0_0_0_onClick = React.useCallback(() => {
        dispatch({ type: "CHANGE", field: "status", value: "closed" })
    }, [])
    return (
        <div ref={domRef} {...htmlAttrs} elem-menu="">
            <div className="menu-button" onClick={handler_0_onClick}>
                <Icon icon="solid/faBars" />
            </div>
            <div className="menu-wrapper">
                <div className={"item " + state?.status}>
                    <div id="menu-overlay">
                        <div className="close-button" onClick={handler_1_0_0_0_onClick}>
                            <Icon icon="solid/faTimes" />
                        </div>
                        <div className="menu">
                            <Link info={info} state={state} dispatch={dispatch} to="/">
                                Home
                            </Link>
                            <Link info={info} state={state} dispatch={dispatch} to="/about-us">
                                About Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Menu
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "menu-button",
            "_e": {
              "className": {
                "is": "exprValExt"
              },
              "onClick": {
                "2": {
                  "1": {
                    "1": {
                      "_type": "op"
                    },
                    "_type": "op"
                  },
                  "_type": "op"
                },
                "_type": "action",
                "is": "exprValExt"
              }
            },
            "onClick": [
              "setState",
              "status",
              [
                "if",
                [
                  "=",
                  [
                    "get",
                    "state",
                    "status"
                  ],
                  "open"
                ],
                "closed",
                "open"
              ]
            ]
          }
        },
        "children": [
          {
            "data": {
              "_e": {
                "name": {
                  "_type": "icon"
                }
              },
              "name": "solid/faBars"
            }
          }
        ]
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "menu-wrapper"
          }
        },
        "children": [
          {
            "data": {
              "name": "div",
              "attr": {
                "className": [
                  "+",
                  "item ",
                  [
                    "get",
                    "state",
                    "status"
                  ]
                ],
                "_e": {
                  "className": {
                    "2": {
                      "1": {
                        "_type": "op"
                      },
                      "_type": "op"
                    },
                    "_type": "op",
                    "is": "exprValExt"
                  }
                }
              }
            },
            "children": [
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "id": "menu-overlay"
                  }
                },
                "children": [
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "className": "close-button",
                        "_e": {
                          "className": {
                            "is": "exprValExt"
                          },
                          "onClick": {
                            "_type": "action"
                          }
                        },
                        "onClick": [
                          "setState",
                          "status",
                          "closed"
                        ]
                      }
                    },
                    "children": [
                      {
                        "data": {
                          "_e": {
                            "name": {
                              "_type": "icon"
                            }
                          },
                          "name": "solid/faTimes"
                        }
                      }
                    ]
                  },
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "className": "menu"
                      }
                    },
                    "children": [
                      {
                        "data": {
                          "name": "Link",
                          "attr": {
                            "to": "/",
                            "content": "Home"
                          }
                        }
                      },
                      {
                        "data": {
                          "name": "Link",
                          "attr": {
                            "to": "/about-us",
                            "content": "About Us"
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "context": {
    "state": {
      "status": "closed"
    }
  }
}
*/
