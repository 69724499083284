import React from "react"
import useAttrs from "ui/hooks/useAttrs"
import { useLocalState } from "ui/hooks/useLocalState"

import renderers from "ui/renderers"

const Section = attrs => {
    const domRef = React.useRef()
    const { info } = attrs
    const { Fields, Icon } = renderers
    const [state, dispatch] = useLocalState(
        { open: true, _e: { open: { _type: "bool" } } },
        attrs.state,
        attrs.dispatch
    )

    const htmlAttrs = useAttrs(attrs)
    const handler_1_0_onClick = React.useCallback(() => {
        dispatch({ type: "CHANGE", field: "open", value: !state?.open })
    }, [state])
    return (
        <section
            ref={domRef}
            {...htmlAttrs}
            elem-section=""
            section-state={state?.open ? "open" : "closed"}
            id={"section-" + attrs?._index}
            type-section=""
        >
            <h2 field-title="">
                <Fields
                    info={info}
                    state={state}
                    dispatch={dispatch}
                    field="e.title"
                    attrs={attrs}
                    isContent={true}
                />
            </h2>
            <div className="ref-opener-outer">
                <div className="ref-opener" onClick={handler_1_0_onClick}>
                    {state?.open === false && <Icon icon="filled/add" />}
                    {state?.open === true && <Icon icon="filled/remove" />}
                </div>
            </div>
            <Fields
                info={info}
                state={state}
                dispatch={dispatch}
                field="e.intro"
                attrs={attrs}
                isContent={undefined}
            />
            {!state?.open && (
                <Fields
                    info={info}
                    state={state}
                    dispatch={dispatch}
                    field="e.teaser"
                    attrs={attrs}
                    isContent={undefined}
                />
            )}
            {state?.open && (
                <Fields
                    info={info}
                    state={state}
                    dispatch={dispatch}
                    field="e.content"
                    attrs={attrs}
                    isContent={undefined}
                />
            )}
        </section>
    )
}
export default Section
/*
{
  "layout": {
    "data": {
      "name": "section",
      "attr": {
        "section-state": [
          "if",
          [
            "f",
            "state.open"
          ],
          "open",
          "closed"
        ],
        "_e": {
          "section-state": {
            "1": {
              "_type": "op"
            },
            "_type": "op",
            "is": "exprValExt"
          },
          "id": {
            "2": {
              "_type": "op"
            },
            "_type": "op"
          }
        },
        "id": [
          "+",
          "section-",
          [
            "get",
            "attrs",
            "_index"
          ]
        ]
      }
    },
    "children": [
      {
        "data": {
          "name": "h2",
          "attr": {
            "content": [
              "f",
              "e.title"
            ],
            "_e": {
              "content": {
                "_type": "op",
                "is": "exprValExt"
              }
            },
            "field-title": ""
          }
        }
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "ref-opener-outer"
          }
        },
        "children": [
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "ref-opener",
                "onClick": [
                  "setState",
                  "open",
                  [
                    "!",
                    [
                      "f",
                      "state.open"
                    ]
                  ]
                ],
                "_e": {
                  "onClick": {
                    "2": {
                      "1": {
                        "_type": "op"
                      },
                      "_type": "op"
                    },
                    "_type": "action"
                  }
                }
              }
            },
            "children": [
              {
                "data": {
                  "_e": {
                    "name": {
                      "_type": "icon"
                    }
                  },
                  "name": "filled/add",
                  "attr": {
                    "if": [
                      "=",
                      [
                        "f",
                        "state.open"
                      ],
                      false
                    ],
                    "_e": {
                      "if": {
                        "1": {
                          "_type": "op"
                        },
                        "2": {
                          "_type": "bool"
                        },
                        "_type": "op"
                      }
                    }
                  }
                }
              },
              {
                "data": {
                  "_e": {
                    "name": {
                      "_type": "icon"
                    }
                  },
                  "name": "filled/remove",
                  "attr": {
                    "if": [
                      "=",
                      [
                        "f",
                        "state.open"
                      ],
                      true
                    ],
                    "_e": {
                      "if": {
                        "1": {
                          "_type": "op"
                        },
                        "2": {
                          "_type": "bool"
                        },
                        "_type": "op"
                      }
                    }
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e.intro"
          ]
        }
      },
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e.teaser"
          ],
          "attr": {
            "if": [
              "!",
              [
                "f",
                "state.open"
              ]
            ],
            "_e": {
              "if": {
                "1": {
                  "_type": "op"
                },
                "_type": "op"
              }
            }
          }
        }
      },
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e.content"
          ],
          "attr": {
            "if": [
              "f",
              "state.open"
            ],
            "_e": {
              "if": {
                "_type": "op"
              }
            }
          }
        }
      }
    ]
  },
  "context": {
    "state": {
      "open": true,
      "_e": {
        "open": {
          "_type": "bool"
        }
      }
    }
  }
}
*/
