//import axios from "axios"
import React from "react"
import lazySizes from "lazysizes"
import { isHydrating, isBrowser, useScreen } from "lib"
import SVG from "./SVG"
//import { screen } from "../hooks/useScreen"
import { selectArt, imgPath } from "./imgUtil"
// check_webp_feature:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
let initDone = false
const setInitDone = () => {
    initDone = true
    //console.log("INIT DONE, calling LAZY")
    requestIdleCallback(() => {
        //console.log("LAZY")
        lazySizes.init()
    })
}
if (!isHydrating()) {
    //console.log("NOT HYDRATING")
    initDone = true
    lazySizes.init()
}
const whiteImg = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="

export const useImage = (src, imageStyle, svg) => {
    const screen = useScreen()
    const [imgurl, imgConfig] = React.useMemo(() => selectArt(src, screen, svg), [src, screen, svg])

    //console.log(imgurl, imgConfig, screen)
    return React.useMemo(
        () => imgPath(imgurl, imageStyle, imgConfig, screen),
        [imgurl, imgConfig, imageStyle, screen]
    )
}
export const useImageUrl = (url, imageStyle) => {
    return useImage({ url }, imageStyle)[0]
}
const LazyImage = (props, ref) => {
    const {
        domRef,
        src,
        imageStyle,
        svg,
        inline,
        style,
        bg,
        alt,
        proportional,
        noth,
        data,
        preload,
        lightbox,
        children,
        _e,
        nolazy,
        notran,
        ...rest
    } = props

    const lazyRef = React.useRef()
    const imgalt = alt ?? ""
    const hydrating = isHydrating()
    if (!initDone && !hydrating) setInitDone()
    //console.log(url)
    const [url, config] = useImage(src, imageStyle, svg)
    //console.log(url, config)
    //console.log(url, src, bg, ref, domRef, url, config, style, rest, hydrating)
    const [OuterTag, outerProps, InnerTag, innerProps] = React.useMemo(
        () =>
            bg
                ? [
                      "div",
                      {
                          ref: ref ?? domRef ?? lazyRef,
                          style: {
                              ...(style ?? {}),
                              "--aspect": config.aspect,
                              ...(nolazy ? { backgroundImage: `url(${url})` } : {}),
                          },
                          ...(nolazy ? {} : { "data-img": url }),
                          "data-bg": "bg",
                          ...rest,
                          className: `${rest.className ?? ""}${nolazy ? "" : " lazyload"}${
                              notran ? "" : " tr"
                          }`,
                      },
                      null,
                      null,
                  ]
                : [
                      "div",
                      {
                          ref: ref ?? domRef ?? lazyRef,
                          style: {
                              ...(style ?? {}),
                              "--aspect": config.aspect,
                              ...(isBrowser && !hydrating && config.w && config.h
                                  ? { "--w-img": config.w, "--h-img": config.h }
                                  : {}),
                          },
                          ...rest,
                      },
                      "img",
                      nolazy
                          ? {
                                src: url,
                                ...(isBrowser && !hydrating && config.w && config.h
                                    ? { width: config.w, height: config.h }
                                    : {}),
                            }
                          : {
                                src: whiteImg,
                                "data-img": url,
                                className: `lazyload${notran ? "" : " tr"}`,
                                ...(isBrowser && !hydrating && config.w && config.h
                                    ? { width: config.w, height: config.h }
                                    : {}),
                            },
                  ],
        [bg, ref, domRef, url, config, style, rest, hydrating, nolazy, notran]
    )
    //const svgInline =
    //console.log(svg, config, inline)
    if (inline) return <SVG url={url} props={outerProps} />

    if (!url) return null
    return bg ? (
        <OuterTag key={url} {...outerProps}>
            {children}
        </OuterTag>
    ) : (
        <OuterTag key={url} {...outerProps}>
            <InnerTag {...innerProps} alt={imgalt} />
        </OuterTag>
    )
}

export default React.forwardRef(LazyImage)
