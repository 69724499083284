import React from "react"
import useAttrs from "ui/hooks/useAttrs"

const NodeWrap = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs

    const htmlAttrs = useAttrs(attrs)
    return (
        <div ref={domRef} {...htmlAttrs} elem-node-wrap="" region-content="">
            <div region-inner="">{attrs.children}</div>
        </div>
    )
}
export default NodeWrap
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "region-content": ""
      }
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "region-inner": "",
            "renderChildren": true,
            "_e": {
              "renderChildren": {
                "_type": "bool"
              }
            }
          }
        }
      }
    ]
  }
}
*/
