import customTypes from "conf/types"
//import C from "../conf"
//import Type from "./type"
//import Data from "./data"
import { compose } from "./schema"
//import { $keyOrder } from "./symbols"
import actions from "./actions"
/*const actions = [
    { label: "Atribut", value: "setAttr" },
    { label: "Mesaj", value: "send" },
    { label: "Stare", value: "setState" },
]*/
const locales = [
    { value: "ro-RO", label: "Română" },
    { value: "en-US", label: "Engleză" },
]
/*const formFieldTypes = [
    { value: "text", label: "Text" },
    { value: "email", label: "Adresă e-mail" },
    { value: "tel", label: "Telefon" },
    { value: "textarea", label: "Text lung" },
    { value: "captcha", label: "Captcha" },
    { value: "submit", label: "Trimite" },
]
/*
const operands = [
    { value: "=", label: "=" },
    { value: "<", label: "<" },
    { value: ">", label: ">" },
    { value: "<=", label: "<=" },
    { value: ">=", label: ">=" },
    { value: "NOT NULL", label: "NOT NULL" },
    { value: "EMPTY", label: "EMPTY" },
]*/

/*
const getExprValLabel = (expr, info) => {
    switch (info.realType) {
        case "map":
            return `{...}`
        case "list":
            return "[...]"
        case "img":
            return "img"
        case "icon":
            return `icon ${expr}`
        case "op":
            return `${expr}`
        default:
            return `${expr}`
    }
}

const dataElementAttributes = (data, dataInfo) => {
    const attr = Data.get(data, "attr", { parentType: dataInfo })
    const attrInfo = Type.getKeyType("attr", data, dataInfo)
    if (!attr || attrInfo?.[$keyOrder]?.length === 0) return ""

    const attrs = attrInfo[$keyOrder].map(key => {
        const aInfo = Type.getKeyType(key, attr, attrInfo)
        //console.log(key, aInfo, attr, attrInfo)
        const val = aInfo?.getLabel?.(attr[key], aInfo) ?? attr[key]
        return `${key}="${val}"`
    })
    const ret = " " + attrs.join(" ")
    if (ret.length > 30) return ret.substring(0, 30) + "..."
    return ret
}
const dataElementGetLabel = (data, dataInfo) => {
    const nameInfo = Type.getKeyType("name", data, dataInfo)
    const attrs = dataElementAttributes(data, dataInfo)
    const tag = nameInfo.getLabel(data.name, nameInfo)
    return `<${tag}${attrs}>`
}*/
/*
const dataObjGetLabel = (data, dataInfo) => {
    const valueInfo = Type.getKeyType("value", data, dataInfo)
    //console.log(valueInfo)
    return (
        valueInfo?.getLabel?.(data?.value, valueInfo) ??
        (Type.is(valueInfo, "string") ? data?.value ?? "" : valueInfo?.label)
    )
    }*/
/*
const treeNodeLabel = (node, nodeInfo) => {
    const dataInfo = Type.getKeyType("data", node, nodeInfo)
    //console.log(dataInfo, node, nodeInfo)
    if (!dataInfo) return ""
    return dataInfo.getLabel(node?.data, dataInfo)
}*/

/*
object 
- type = typeName
- _e: {
   key: {name: keyName, type: typeName} <- settings object 
         => {type: type, base: typeName}
}
_e:  {
   key: partialType 
       => 
          case !type: nothing
          case type!=type: rename(type -> base), ignore: (name), type=type
} 
type object
- name = typeName
- type = type
- base = parentType
=> lineage = [name, ...bases]

*/
/*const basicTypeNames = ["any", "union", "bool", "string", "number", "list", "map", "ref"]
const basicKeys = {
    any: {
        allow: "anyList", // list of values to allow
        cast: "string", // castTypes, // cast input value to
        concat: "string", // another set of constraints to concat
        custom: "string", // custom validation function
        default: "any", // default value
        description: "string",
        empty: "any", // empty value -> error
        error: "string", // custom error
        example: "string", // value example
        extract: "string", // return a sub-schema
        failover: "any", // set value if validation fails
        forbidden: "string", // forbid key
        invalid: "list", // invalid values
        label: "string",
        message: "string",
        messages: "list",
        meta: "any", // attach meta data
        note: "string",
        notes: "stringList", // of string
        only: "bool", // only "allow" values
        optional: "bool", // optional key
        prefs: "map", // options to use
        presence: "", // optional, required, forbidden
        raw: "bool", // raw value, not casted
        required: "bool",
        result: "", // raw or strip
        rule: "", // options: ?
        ruleset: "",
        strict: "bool",
        strip: "bool", // remove from result
        tag: "stringList", // of strings
        tailor: "stringList",
        unit: "string",
        valid: "anyList",
        warn: "bool",
        when: "", //
    },
    list: {
        has: "schema", // schema validates at least one value
        items: "string", // items type
        length: "number",
        max: "number",
        min: "number",
        single: "bool", // allow single value as list of one
        sort: "string",
        sparse: "bool",
        unique: "bool",
    },
    number: {
        greater: "number",
        integer: "bool",
        less: "number",
        max: "number",
        min: "number",
        multiple: "number",
        negative: "bool",
        positive: "bool",
        precision: "number",
    },
    map: {
        and: "stringList", // keys all or nothing
        append: "keyMap", // extend base
        keys: "keyMap", // sets allowed keys
        length: "number", // number of keys
        max: "number",
        min: "number",
        nand: "stringList", // keys not all
        or: "stringList", // keys
        oxor: "stringList", // keys not all
        pattern: "string",
        rename: "string",
        schema: "bool",
        unknown: "bool",
        with: "stringList",
        without: "stringList",
        xor: "stringList",
    },
    string: {
        alphanum: "bool",
        //_case: "string",
        //creditCard: "bool",
        email: "bool",
        pattern: "string",
        replace: "stringList",
        trim: "bool",
        max: "number",
        min: "number",
        truncate: "bool",
    },
}

const typeNames = () => []
*/
const types = Object.keys(customTypes).reduce(
    (acc, typeName) => ({
        ...acc,
        [typeName]: acc[typeName]
            ? compose(acc[typeName], customTypes[typeName])
            : customTypes[typeName],
    }),
    {
        t: {
            classes: ["type"],
        },
        any: {
            is: "t",
        },
        fun: {
            is: "t",
        },
        bool: {
            label: "Boolean",
            is: "t",
            icon: "filled/check_box",
        },
        string: {
            is: "t",
            label: "Text simplu",
            icon: "filled/title",
        },
        number: {
            is: "t",
            label: "Număr",
            icon: "filled/looks_one",
            settings: {
                decimals: {
                    is: "int",
                    label: "Zecimale",
                },
            },
        },
        list: {
            is: "t",
            label: "Listă",
            icon: "filled/format_list_numbered",
            classes: ["list"],
            settings: {
                items: {
                    is: "typeInfo",
                    label: "Item Info",
                },
            },
        },
        typeName: {
            is: "string",

            case: [
                {
                    cond: { $eq: "union" },

                    concat: {
                        settings: {
                            _type: {
                                is: "string",
                                label: "Tip actual",
                                values: "unionTypeOptions",
                            },
                        },
                    },
                },
            ],
        },
        typeDef: {
            is: "map",
            keys: {
                is: { is: "string", label: "Tip" },
            },

            case: [
                {
                    target: "is",
                    cond: { $eq: "union" },

                    concat: {
                        settings: {
                            _type: {
                                is: "string",
                                label: "Tip actual",
                                values: "unionTypeOptions",
                            },
                        },
                    },
                },
            ],
        },
        typeInfo: {
            label: "Type Info",
            is: "union",
            types: ["typeName", "typeDef"],
            case: [
                {
                    cond: { $not: { $typeof: "string" } },
                    concat: {
                        _type: "typeDef",
                    },
                },
            ],
        },
        map: {
            is: "t",
            label: "Map",
            icon: "filled/format_list_bulleted",
            classes: ["map"],
            _forbidden: ["_c", "_e", "_o", "type"],
        },
        union: {
            is: "t",
            label: "Union",
            icon: "filled/alt_route",
            classes: ["union"],
            settings: {
                _type: {
                    is: "string",
                    label: "Tip actual",
                    values: "unionTypeOptions",
                },
            },
        },
        anyList: {
            is: "list",
            items: "any",
        },
        stringList: {
            is: "list",
            items: "string",
        },
        entity: {
            is: "map",
            classes: ["entity", "create"],
            _forbidden: ["_id", "_created", "_updated", "_pub", "_refs"],
        },
        node: {
            label: "Nod",
            is: "entity",
            classes: ["node"],
            collection: "node",
            _forbidden: ["path", "_seo"],
        },
        type: {
            is: "entity",
            label: "Tip de date",
            collection: "types",

            keys: {
                label: {
                    is: "string",
                    label: "Nume",
                    form: {
                        append: {
                            "name._hidden": { label: "Nume intern automat" },
                        },
                    },
                },
                name: { is: "string", label: "Nume intern", _hidden: true },
            },
            //concat: { keys: "schema" },
        },
        /*schema: {
            is: "map",
            keys: {
                is: { is: "string", label: "Tip părinte", allow: typeNames, required: true },
            },
            concat: { keys: basicKeys.any },
            case: [
                {
                    target: "lineage",
                    contains: "string",
                    then: { concat: { keys: basicKeys.string } },
                },
                {
                    target: "lineage",
                    contains: "number",
                    then: { concat: { keys: basicKeys.number } },
                },
                {
                    target: "lineage",
                    contains: "list",
                    then: { concat: { keys: basicKeys.list } },
                },
                {
                    target: "lineage",
                    contains: "map",
                    then: { concat: { keys: basicKeys.map } },
                },
            ],
        },*/
        text: {
            is: "string",
            classes: ["widget", "display"],
            label: "Text",
            _closed: true,
            settings: {
                linkTo: { is: "string", label: "Legătură către" },
                iconBefore: { is: "icon", label: "Iconiță în față" },
                iconAfter: { is: "icon", label: "Iconiță în spate" },
            },
        },
        record: {
            is: "map",
            _closed: true,
            //classes: ["display"],
        },
        int: {
            label: "Număr întreg",
            is: "number",
        },
        color: {
            label: "Culoare",
            is: "string",
        },
        html: {
            is: "t",
            label: "HTML",
            classes: ["widget"],
            icon: "filled/text_snippet",
            editor: { collapse: "teaser", teaserFun: "htmlTeaser" },
            /*settings: {
                iconBefore: { is: "icon", label: "Iconiță în față" },
                iconAfter: { is: "icon", label: "Iconiță în spate" },
            },*/
        },
        date: {
            is: "t",
            label: "Data",
            icon: "filled/today",
            setValue: value => {
                if (value === "now") return new Date()
                return value
            },
            settings: { showTime: { is: "bool", label: "Data și Ora", widget: "toggle" } },
        },
        imgInfo: {
            label: "Info imagine",
            is: "map",
            keys: {
                url: { is: "string", label: "URL" },
            },
        },
        img: {
            label: "Imagine",
            is: "list",
            classes: ["widget", "display"],
            items: "imgInfo",
            dynamic: true,
            settings: {
                //_style: { is: "text", label: "Stil" },
                //_carousel: { is: "bool", label: "Carusel", widget: "toggle" },
                /*_slideshow: {
                    is: "map",
                    label: "Slideshow",
                    keys: { i: { is: "int", label: "Interval", end: "ms" } },
                    option: true,
                },*/
                bg: { is: "bool", label: "Fundal", require: "admin" },
                /*wrap: {
                    is: "bool",
                    label: "Containere individuale",
                    require: "admin",
                },
                proportional: {
                    is: "bool",
                    label: "Proporțional",
                    require: "admin",
                },*/
            },
            editor: { nocollapse: true },
        },
        video: {
            label: "Video",
            icon: "filled/ondemand_video",
            classes: ["widget", "display"],
            dynamic: true,
            is: "union",
            types: [
                { is: "text", label: "Link video" },
                {
                    is: "file",
                    icon: "filled/videocam",
                    settings: {
                        playsInline: {
                            is: "bool",
                            label: "PlaysInline",
                            default: true,
                            widget: "toggle",
                        },
                        constrols: {
                            is: "bool",
                            label: "Controls",
                            default: true,
                            widget: "toggle",
                        },
                        muted: { is: "bool", label: "Muted", widget: "toggle" },
                        autoPlay: { is: "bool", label: "AutoPlay", widget: "toggle" },
                        loop: { is: "bool", label: "Loop", widget: "toggle" },
                        poster: { is: "img", label: "Poster" },
                    },
                },
            ],
        },
        doc: {
            label: "Doc",
            is: "file",
            classes: ["widget", "display"],
        },
        file: {
            is: "t",
            label: "Fișier",
        },
        class: {
            label: "Clasă",
            is: "string",
        },
        href: {
            label: "URL",
            is: "string",
            isEmpty: value => !value || value === "",
            _nowrap: true,
        },
        path: {
            label: "Cale",
            is: "string",
        },
        refid: {
            label: "ID Ref",
            is: "string",
        },
        ref: {
            is: "t",
            label: "Reference",
            classes: ["ref"],
        },
        reference: {
            label: "Entitate încorporată",
            icon: "filled/extension",
            is: "ref",
            renderer: "Reference",
        },
        icon: {
            label: "Iconiță",
            icon: "filled/insert_emoticon",
            is: "union",
            classes: ["widget"],
            types: [
                { is: "text", label: "Stock", renderer: "Icon" },
                { is: "img", label: "Imagine" },
            ],
            dynamic: true,
            _nowrap: true,
        },
        select: {
            label: "Selecție",
            icon: "filled/view_list",
            is: "map",
            classes: ["widget"],
            keys: {
                entityType: { is: "string", values: "getEntityTypesValues", label: "Tip" },
                limit: { is: "number", label: "Limita", optional: true },
                cond: { is: "attrList", label: "Condiții", optional: true, min: 1 },
            },
            prerender: "Select",
            isBlock: true,
            _contextFun: "selectContext",
            editor: { teaserFun: "selectTeaser" },
        },
        searchIndex: {
            label: "Index",
            collection: "site",
            is: "entity",
            keys: {
                title: { is: "string", label: "Titlu" },
                name: { is: "string", label: "Nume" },
                types: {
                    is: "list",
                    items: "searchIndexType",
                    min: 1,
                    editor: { widget: "table", nocollapse: true },
                    label: "Entități indexate",
                },
            },
        },
        searchIndexType: {
            label: "Entitate indexata",
            is: "record",
            keys: {
                name: { is: "string", values: "getEntityTypesValues", label: "Entitate" },
                fields: {
                    is: "list",
                    items: "string",
                    label: "Cîmpuri",
                    editor: { widget: "string", sep: ",", nowrap: true },
                },
            },
        },
        search: {
            label: "Căutare",
            classes: ["widget"],
            is: "map",
            keys: {
                index: { is: "string", label: "Index" },
                limit: { is: "number", label: "Limită", optional: true },
            },
            renderer: "Search",
            isBlock: true,
            _contextFun: "searchContext",
            prerender: "Search",
            //editor: { teaserFun: "searchTeaser" },
        },
        searchText: {
            is: "string",
            label: "Search",
        },
        doSearch: {
            is: "bool",
            label: "Exec Search",
        },
        /*view: {
            label: "Vizualizare",
            icon: "filled/view_list",
            dynamic: true,
            is: "record",
            //classes: ["widget"],
            keys: {
                title: { is: "text", label: "Titlu" },
                view: { is: "text", label: "Vizualizare" },
                collection: { is: "text", label: "Collection" },
                query: { is: "text", label: "Interogare" },
                projection: { is: "text", label: "Proiecție" },
                sort: { is: "text", label: "Ordonare" },
                limit: { is: "text", label: "Limită" },
                pipeline: { is: "text", label: "Pipeline", multiLine: true, rows: 5 },
                display: { is: "text", label: "Afișare" },
                pageSize: { is: "text", label: "Pagină" },
                moreLink: { is: "href", label: "Legătură 'Mai mult...'" },
                moreText: { is: "text", label: "Text 'Mai mult...'" },
            },
            //isEmpty: value => !value || !value.path || value.path === "",
            _nowrap: true,
            renderer: "View",
        },*/
        seo: {
            label: "SEO",
            is: "record",
            keys: {
                title: { is: "text", label: "Titlu", fullWidth: true },
                description: {
                    is: "text",
                    label: "Descriere",
                    fullWidth: true,
                    multiLine: true,
                    rows: 3,
                },
                image: { is: "img", label: "Imagine" },
            },
            widgetProps: { nolabel: true },
        },
        display: {
            label: "Afișare",
            is: "record",
            keys: {
                name: { is: "string", label: "Nume" },
                guard: {
                    label: "Condiții",
                    is: "exprVal",
                    optional: true,
                },
                layout: {
                    is: "displayNode",
                    label: "Layout",
                    editor: { nocollapse: true, widget: "tree" },
                },
                context: { is: "displayContext", optional: true },
            },
            editor: { nowrap: true },
            _contextFun: "elementDisplayContext",
        },
        listMap: {
            label: "ListMap",
            is: "map",
            values: "string",
        },
        displayContext: {
            label: "Context",
            is: "map",
            keys: {
                state: {
                    is: "map",
                    label: "Stare",
                    values: "exprVal",
                    optional: true,
                    editor: { nocollapse: true },
                },
                subscriptions: {
                    is: "list",
                    label: "Subscripții",
                    items: "subscription",
                    optional: true,
                    editor: { widget: "chips", nowrap: true, nocollapse: true },
                },
                handlers: {
                    is: "list",
                    label: "Handlers",
                    items: "handler",
                    optional: true,
                    editor: { widget: "table", nocollapse: true },
                },
            },
            editor: { nocollapse: true },
        },
        dataElement: {
            label: "Element",
            is: "map",
            keys: {
                name: {
                    is: "exprVal",
                    label: "Tag",
                },
                attr: {
                    is: "map",
                    values: "exprValExt",
                    label: "Atribute",
                    editor: { nocollapse: true },
                },
            },
            editor: { nowrap: true },
        },
        attrs: {
            is: "map",
            keys: {
                _index: { is: "int" },
            },
            values: "exprValExt",
            label: "Atribute",
            editor: { nocollapse: true },
        },
        displayNode: {
            label: "Nod",
            is: "record",
            keys: {
                data: {
                    is: "dataElement",
                    label: "Data",
                    editor: { nowrap: true },
                },
                children: { is: "list", items: "displayNode", label: "Children" },
            },
            _contextFun: "displayNodeContext",
        },
        attrList: {
            label: "Listă Atribute",
            is: "list",
            items: "attr",
            editor: { widget: "table", nocollapse: true },
        },
        attr: {
            label: "Atribut",
            is: "record",
            keys: {
                a: { is: "string", label: "Atribut" },
                v: { is: "exprValExt", label: "Valoare", editor: { nowrap: true } },
            },
            _o: "a,v",
        },
        mapEdit: {
            label: "Editare map",
            is: "map",
            keys: {
                items: {
                    is: "list",
                    items: "mapEditEntry",
                    editor: { widget: "table", nowrap: true },
                    label: "Items",
                },
            },
        },
        mapEditEntry: {
            label: "Editare map - Item",
            is: "map",
            keys: {
                key: { is: "string", label: "Atribut" },
                value: { is: "union", label: "Valoare" },
            },
            _o: "key,value",
        },
        selectResult: {
            label: "Rezultat",
            is: "map",
            keys: {
                items: { is: "list", items: "union", label: "Rînduri" },
                status: { is: "string", label: "Status" },
                total: { is: "int", label: "Total" },
            },
            classes: ["create"],
        },
        expr: {
            is: "union",
            types: [
                { is: "string", label: "Text" },
                { is: "int" },
                { is: "number" },
                { is: "op", label: "Operatie" },
                { is: "attrList", label: "Atribute" },
                { is: "list", items: "expr" },
            ],
            editor: { variant: "standard", margin: "none" },
            moreTypes: true,
        },
        exprVal: {
            is: "union",
            types: [
                { is: "string" },
                { is: "int" },
                { is: "number" },
                {
                    is: "op",
                    label: "Operatie",
                    editor: { nowrap: false, nocollapse: true, widget: "inline" },
                },
                { is: "attrList", label: "Atribute" },
                { is: "list", items: "expr" },
                { is: "img", label: "Imagine" },
                { is: "icon", label: "Iconiță" },
            ],
            moreTypes: true,
            editor: { variant: "standard", margin: "none" },
        },
        exprValExt: {
            label: "Expresie",
            is: "union",
            types: [
                { is: "string" },
                { is: "int" },
                { is: "number" },
                {
                    is: "op",
                    label: "Operatie",
                    editor: { nowrap: false, nocollapse: true, widget: "inline" },
                },
                {
                    is: "action",
                    label: "Acțiune",
                    editor: { nowrap: false, nocollapse: true, widget: "inline" },
                },
                {
                    is: "actionList",
                    label: "Listă acțiuni",
                    editor: { nowrap: false, nocollapse: true },
                },
                //{ is: "map", values: "expr", label: "Atribute old" },
                { is: "attrList", label: "Atribute" },
                { is: "list", items: "expr" },
                { is: "img", label: "Imagine" },
                { is: "icon", label: "Iconiță" },
            ],
            editor: { variant: "standard", margin: "none" },
            moreTypes: true,
        },
        oper: {
            is: "string",
            label: "Operator",
            values: [
                "+",
                "-",
                "/",
                "*",
                "**",
                "&&",
                "||",
                "!",
                "if",
                { value: "f", label: "Cîmp" },
                "len",
                "get",
                "=",
                "!=",
                ">",
                ">=",
                "<",
                "<=",
                "map",
                "red",
                "fil",
                "??",
                "is",
                { value: "a", label: "Acțiune" },
                { value: "fmt", label: "Format" },
                { value: "att", label: "Atribute" },
                { value: "val", label: "Valori" },
                { value: "ent", label: "Entries" },
                { value: "zip", label: "Zip" },
                { value: "iu", label: "URL Imagine" },
            ],
            default: "f",
            editor: { variant: "standard", margin: "none" },
        },
        op: {
            is: "list",
            icon: "filled/functions",
            items: "expr",
            keys: ["oper"],
            default: ["f", null],
            min: 2,
            editor: { nowrap: true, widget: "inline" },
            _contextFun: "opContext",
            case: [
                {
                    target: "0",
                    cond: { $in: ["+", "-", "*", "&&", "||", "??"] },
                    concat: { min: 3 },
                },
                {
                    target: "0",
                    cond: { $in: ["/", "**"] },
                    concat: { min: 3, max: 3 },
                },
                {
                    target: "0",
                    cond: { $in: ["if"] },

                    concat: {
                        min: 3,
                        max: 4,
                        keys: [
                            "oper",
                            { is: "expr", label: "Condiție" },
                            "expr",
                            { is: "expr", label: "Else" },
                        ],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["map"] },

                    concat: {
                        min: 3,
                        max: 3,
                        keys: [
                            "oper",
                            { is: "expr", label: "Target" },
                            { is: "mapper", label: "Funcție" },
                        ],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["red"] },

                    concat: {
                        min: 4,
                        max: 4,
                        keys: [
                            "oper",
                            { is: "expr", label: "Target" },
                            { is: "reducer", label: "Reducer" },
                            { is: "expr", label: "Init" },
                        ],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["fil"] },

                    concat: {
                        min: 3,
                        max: 3,
                        keys: [
                            "oper",
                            { is: "expr", label: "Target" },
                            { is: "filter", label: "Filtru" },
                        ],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["!", "len"] },
                    concat: { min: 2, max: 2 },
                },
                {
                    target: "0",
                    cond: { $in: ["=", "!=", ">", ">=", "<", "<="] },
                    concat: { min: 3, max: 3 },
                },
                {
                    target: "0",
                    cond: { $in: ["a"] },

                    concat: {
                        min: 2,
                        keys: ["oper", { is: "string", label: "Acțiune" }],
                        //, values: actions
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["get"] },

                    concat: {
                        min: 3,
                        max: 3,
                        keys: [
                            "oper",
                            { is: "fieldName", label: "Target" },
                            { is: "expr", label: "Cîmp" },
                        ],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["f"] },

                    concat: {
                        min: 2,
                        max: 2,
                        keys: ["oper", { is: "fieldName", label: "Cîmp" }],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["is"] },

                    concat: {
                        min: 3,
                        max: 3,
                        keys: [
                            "oper",
                            { is: "expr", label: "Target" },
                            { is: "string", label: "Tip" },
                        ],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["fmt"] },

                    concat: {
                        min: 4,
                        max: 4,
                        keys: ["oper", "expr", "locale", { is: "int", label: "Zecimale" }],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["att", "val", "ent"] },

                    concat: {
                        min: 2,
                        max: 2,
                        keys: ["oper", { is: "expr", label: "Target" }],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["zip"] },

                    concat: {
                        min: 3,
                        max: 3,
                        keys: [
                            "oper",
                            { is: "expr", label: "Listă" },
                            { is: "expr", label: "Listă" },
                        ],
                    },
                },
                {
                    target: "0",
                    cond: { $in: ["iu"] },

                    concat: {
                        min: 3,
                        max: 3,
                        keys: [
                            "oper",
                            { is: "expr", label: "Target" },
                            { is: "string", label: "Stil" },
                        ],
                    },
                },
            ],
        },
        reducer: {
            is: "expr",
            _contextFun: "reducerContext",
        },
        filter: {
            is: "expr",
            _contextFun: "filterContext",
        },
        mapper: {
            is: "expr",
            _contextFun: "mapContext",
        },
        locale: {
            is: "string",
            label: "Localizare",
            values: locales,
        },
        fieldName: {
            is: "string",
            label: "Cîmp",
            valuesFun: "entityFields",
            editor: { autocomplete: true },
        },
        subscription: {
            label: "Subscripție",
            is: "string",
            values: "subscriptions",
        },
        handler: {
            label: "Handler",
            is: "record",
            keys: {
                message: { is: "string", label: "Mesaj", values: "subscriptions" },
                handler: { is: "actionList" },
            },
            _contextFun: "handlerContext",
        },
        elementName: {
            label: "Nume element",
            is: "string",
            values: "elementNames",
        },
        actionName: {
            label: "Acțiune",
            is: "string",
            values: Object.keys(actions).map(a => ({ value: a, label: actions[a].label })),
            default: Object.keys(actions)[0],
        },
        action: {
            label: "Acțiune",
            is: "list",
            icon: "filled/bolt",
            items: "expr",
            keys: ["actionName"],
            min: 1,

            case: Object.keys(actions).map(a => ({
                target: "0",
                cond: { $in: [a] },
                concat: actions[a].config,
            })),

            editor: { nowrap: true, widget: "inline" },
            _contextFun: "actionContext",
        },
        actionList: {
            label: "Listă acțiuni",
            is: "list",
            items: "action",
            min: 1,
        },
        args: {
            label: "Argumente",
            is: "list",
            items: "t",
        },
        value: {
            is: "record",
            keys: {
                value: { is: "t" },
            },
        },
        ld: {
            is: "entity",
            label: "Linked Data",
            collection: "ld",
            keys: {
                title: { is: "text", label: "Nume" },
                map: { is: "ldMap", label: "Data" },
            },
        },
        ldValue: {
            label: "LD Value",
            is: "union",
            types: ["string", "ldList", "ldMap"],
        },
        ldEntry: {
            label: "LD Entry",
            is: "record",
            keys: {
                key: { is: "string", label: "Etichetă" },
                value: { is: "ldValue", label: "Valoare" },
            },
            editor: "entry",
        },
        ldList: {
            label: "LD List",
            is: "list",
            items: "string",
        },
        ldMap: {
            label: "LD Map",
            is: "list",
            items: "ldEntry",
        },
        element: {
            is: "entity",
            label: "Element",
            collection: "site",
            searchField: "name",
            refOptions: { searchField: "name" },
            keys: {
                name: { is: "string", label: "Nume" },
                displays: {
                    is: "list",
                    items: "display",
                    min: 1,
                    label: "Afisare",
                    editor: { nocollapse: true, widget: "tabs" },
                },
                parent: { is: "ref", ref: "element", cache: "name", label: "Părinte" },
            },
        },
        elemWidget: {
            label: "Element",
            is: "map",
            classes: ["widget"],
            keys: {
                element: { is: "ref", ref: "element", cache: "name", label: "Element" },
                attrs: {
                    is: "map",
                    values: "exprValExt",
                    label: "Atribute",
                    editor: { nocollapse: true },
                },
            },
            isBlock: true,
            renderer: "ElemWidget",
        },
        layoutWidget: {
            label: "Layout",
            is: "record",
            classes: ["widget"],
            keys: {
                layout: {
                    is: "displayNode",
                    label: "Layout",
                    editor: { nowrap: true, widget: "tree" },
                },
                context: { is: "displayContext", optional: true },
            },
            isBlock: true,
            renderer: "LayoutWidget",
        },
        homepage: {
            is: "node",
            label: "Acasă",
            _noCreate: true,
        },
        page: {
            is: "node",
            label: "Pagină",
            info: "Pagină generică",
            searchField: "title",
            keys: {
                title: { is: "text", label: "Titlu" },
            },
        },
        error: {
            is: "node",
            collection: null,
            label: "Pagină inexistentă",
            _noCreate: true,
            keys: {
                title: { is: "text", label: "Titlu" },
                body: { is: "text", label: "Conținut" },
            },
        },
        cookies: {
            label: "Cookies",
            is: "bool",
            _persist: "localStorage",
        },
        submission: {
            is: "entity",
            label: "Submission",
            collection: "submission",
            keys: {
                kind: { is: "string", label: "Tip" },
                payload: { is: "t", label: "Payload" },
                ip: { is: "string", label: "IP" },
            },
        },
        closeLightbox: {
            is: "string",
            label: "Închide Lightbox",
        },
        carousel: {
            is: "map",
            label: "Carousel",
            isBlock: true,
            _contextFun: "carouselContext",
            renderer: "Carousel",
        },
    }
)
//console.log(types)
export default types
