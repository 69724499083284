import React from "react"
import { registerForUpdates, unregisterForUpdates } from "../db/db"

const useLiveValue = (getter, updater) => {
    const result = React.useRef(getter())
    const [, triggerRender] = React.useState(0)
    React.useEffect(() => {
        const id = registerForUpdates(updateId => {
            result.current = updater ? updater(updateId) : getter(updateId)
            triggerRender(n => (n + 1) % 100)
        })
        return () => {
            unregisterForUpdates(id)
        }
    }, [getter, updater])
    return result.current
}
export default useLiveValue
