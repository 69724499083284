import React from "react"
import { registerForUpdates, unregisterForUpdates } from "./db"

const getFilter = options => doc => {
    const keys = Object.keys(options)
    return keys.reduce((acc, key) => {
        if (!acc) return acc
        return doc[key] === options[key]
    }, true)
}
const find = finderQuery => {
    //, config
    if (!finderQuery) return []

    const { collection, query } = finderQuery //, projection
    if (!query) return []
    const { type, ...options } = query
    const db = typeof window === "undefined" ? global._db : window._db
    const coll = db?.[collection]?.data
    if (!coll) return []
    let res = []
    if (type) res = Object.values(coll).filter(doc => doc.type === type)
    if (options) {
        const apply = getFilter(options)
        res = res.filter(apply)
    }
    return res
}
const useFinder = (finderQuery, config) => {
    const results = React.useRef()
    const [, triggerRender] = React.useState(0)
    React.useMemo(() => {
        results.current = find(finderQuery, config)
    }, [finderQuery, config])
    //console.log("USE FINDER", finderQuery, results.current)
    React.useEffect(() => {
        const id = registerForUpdates(() => {
            results.current = find(finderQuery, config)
            triggerRender(n => (n + 1) % 100)
        })
        return () => {
            unregisterForUpdates(id)
        }
    }, [finderQuery, config])
    return [results.current]
}
export default useFinder
