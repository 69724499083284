import React from "react"
import { Entity } from "lib"
import useDb from "lib/db/useDb"
//import calc from "../../entity/calc"

const getValue = (doc, a) => {
    if (!doc || !a) return null
    const path = a.split(".")
    return path.reduce((acc, item) => acc[item], doc)
}
const useSelection = (info, args) => {
    //const args = info.args
    const db = useDb()
    const value = info.value
    return React.useMemo(() => {
        const type = args?.entityType ?? value?.entityType
        const collection =
            args.collection ?? (type ? Entity.typeFromTypeDef(type)?.collection : null)
        if (!collection) return []
        //const db = typeof window === "undefined" ? global._db : window._db
        const coll = db?.[collection]?.data
        if (!coll) return []
        const limit = parseInt(args?.limit ?? value?.limit ?? "0")
        let docs = Object.values(coll).filter(doc => doc.type === type)

        const cond = value?.cond ?? args?.cond
        //const condInfo = Entity.getKeyType("cond", value, info.typeInfo)
        //if (info.typeInfo?.is === "select") cond = value?.cond
        //console.log(cond)
        if (!cond) return limit > 0 ? docs.slice(0, limit) : docs
        /*
        const condInfo = Entity.getKeyType("cond", value, info.typeInfo)
        const filters = cond.map((a, i) => {
            const vInfo = Entity.getKeyType(`${i}.v`, cond, condInfo)
            const realType = Entity.realType(vInfo)
            //console.log(a, realType)
            if (realType === "op") {
                const v = calc(
                    Entity.getValue(cond, `${i}.v`, { parentType: condInfo }),
                    info.context
                )
                //console.log(v, cond, info.context)
                return {
                    ...a,
                    v: v.value,
                    _e: { ...a._e, v: v._e._value },
                }
            }
            return a
        })
        //console.log(cond, filters)
        */
        let n = 0
        const filters = Object.entries(cond)
        return docs.filter(doc => {
            if (limit > 0 && n >= limit) return false
            return filters.reduce((acc, condItem) => {
                if (!acc) return acc
                if (!condItem) return true
                //console.log(condItem)
                return getValue(doc, condItem[0]) === condItem[1]
                /*
                const vInfo = Entity.getKeyType(`${i}.v`, cond, condInfo)
                const realType = Entity.realType(vInfo)
                //console.log(a, realType)
                if (realType === "op") {
                    const v = calc(
                        Entity.getValue(cond, `${i}.v`, { parentType: condInfo }),
                        info.context
                    )
                    return {
                        ...a,
                        v: v.value,
                        _e: { ...a._e, v: v._e._value },
                    }
                }
                return a
                */
            }, true)
        })
    }, [db, args, value])
}

/*
const useSelection = info => {
    //console.log(args, info)
    const args = info.args
    const value = info.value
    //console.log(info.value, info)
    const cond = React.useMemo(() => {
        const cond = value?.cond ?? args?.cond
        const condInfo = Entity.getKeyType("cond", value, info.typeInfo)
        //if (info.typeInfo?.is === "select") cond = value?.cond
        //console.log(cond)
        if (!cond) return []
        return cond.map((a, i) => {
            const vInfo = Entity.getKeyType(`${i}.v`, cond, condInfo)
            const realType = Entity.realType(vInfo)
            //console.log(a, realType)
            if (realType === "op") {
                const v = calc(
                    Entity.getValue(cond, `${i}.v`, { parentType: condInfo }),
                    info.context
                )
                return {
                    ...a,
                    v: v.value,
                    _e: { ...a._e, v: v._e._value },
                }
            }
            return a
        })
        //const res = parseAttr(args.cond)
    }, [args, value, info])

    //console.log(cond, condAttrs) //, condAttrs, args.cond, value.cond, info.context)
    const query = React.useMemo(() => {
        const limit = parseInt(args?.limit ?? value?.limit ?? "0")
        const type = args?.entityType ?? value?.entityType
        const collection =
            args.collection ?? (type ? Entity.typeFromTypeDef(type)?.collection : null)
        if (!collection) return null
        return {
            collection,
            query: {
                ...(type ? { type } : {}),
                ...(cond?.reduce((acc, a) => (a ? { ...acc, [a.a]: a.v } : acc), {}) ?? {}),
            },
            ...(limit ? { limit } : {}),
        }
    }, [args, value, cond])

    console.log("Q", query)
    return useQuery(query)
}
*/
const Select = ({ info, children, ...args }) => {
    //const result = useSelection(args)
    //const args = info.args
    const results = useSelection(info, args)
    const status = "loaded"
    //console.log(results)
    const total = results?.length ?? 0
    //console.log("SELECT", results, status, total)
    const newInfo = React.useMemo(() => {
        const context = {
            ...(info.context ?? {}),
            selectTotal: total ?? 0,
            selectStatus: status,
            selectResults: results ?? [],
            _e: {
                ...(info.context._e ?? {}),
                selectTotal: "int",
                selectStatus: "string",
                selectResults: { is: "list", items: args?.entityType ?? info.value?.entityType },
            },
        }
        return { ...info, context }
    }, [info, results, status, total, args])
    //console.log(children, results)
    /*if (!children) {
        const keys = Entity.keyOrder(info.typeInfo)?.filter(
            key => info.typeInfo.keys[key].keyType === "dynamic"
        )
        //console.log(keys, newInfo, args)
        //console.log(info, element, props, itemAttrs)
        return (
            <>
                {keys?.map((key, i) => (
                    <Element
                        key={i}
                        info={newInfo}
                        state={state}
                        dispatch={dispatch}
                        fieldName={key}
                        attrs={args}
                    />
                ))}
            </>
        )
    }*/
    //console.log(info, results)
    return children(newInfo)
}

export default Select
