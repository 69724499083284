import React from "react"
import useAttrs from "ui/hooks/useAttrs"
import { getImageUrl } from "lib/ui/imgUtil"

import renderers from "ui/renderers"

const DomainTeaser = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { Link, Fields, WithTooltip } = renderers

    const htmlAttrs = useAttrs(attrs)
    return (
        <WithTooltip info={info} tooltip="info.context.domain?.title">
            {tooltipProps => (
                <div
                    ref={domRef}
                    {...htmlAttrs}
                    elem-domain=""
                    {...tooltipProps}
                    className="domain"
                    type-domain=""
                >
                    <Link
                        info={info}
                        state={state}
                        dispatch={dispatch}
                        to={info.context.domain?.path}
                        className="domain-inner"
                    >
                        <svg viewBox="0 0 100 100">
                            <defs>
                                <mask id={"domain-mask-" + attrs?._index}>
                                    <circle fill="#FFFFFF" cx="50" cy="50" r="50" />
                                    <circle
                                        className="inner"
                                        fill="#000000"
                                        cx="50"
                                        cy="50"
                                        r="25"
                                    />
                                </mask>
                            </defs>
                            <image
                                xlinkHref={getImageUrl(info.context.e?.thumb?.[0], "thumb2")}
                                mask={"url(#domain-mask-" + attrs?._index + ")"}
                                width="100"
                                height="100"
                            />
                        </svg>
                        <div className="domain-name">
                            <div>
                                <Fields
                                    info={info}
                                    state={state}
                                    dispatch={dispatch}
                                    field="domain.short_title"
                                    attrs={attrs}
                                    isContent={true}
                                />
                            </div>
                        </div>
                    </Link>
                </div>
            )}
        </WithTooltip>
    )
}
export default DomainTeaser
/*
{
  "name": "teaser",
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "className": "domain",
        "_e": {
          "className": {
            "is": "exprValExt"
          },
          "tooltip": {
            "_type": "op"
          }
        },
        "tooltip": [
          "f",
          "domain.title"
        ]
      }
    },
    "children": [
      {
        "data": {
          "name": "Link",
          "attr": {
            "to": [
              "f",
              "domain.path"
            ],
            "_e": {
              "to": {
                "_type": "op",
                "is": "exprValExt"
              }
            },
            "className": "domain-inner"
          }
        },
        "children": [
          {
            "data": {
              "name": "svg",
              "attr": {
                "viewBox": "0 0 100 100"
              }
            },
            "children": [
              {
                "data": {
                  "name": "defs"
                },
                "children": [
                  {
                    "data": {
                      "name": "mask",
                      "attr": {
                        "id": [
                          "+",
                          "domain-mask-",
                          [
                            "get",
                            "attrs",
                            "_index"
                          ]
                        ],
                        "_e": {
                          "id": {
                            "2": {
                              "_type": "op"
                            },
                            "is": "exprValExt",
                            "_type": "op"
                          }
                        }
                      }
                    },
                    "children": [
                      {
                        "data": {
                          "name": "circle",
                          "attr": {
                            "fill": "#FFFFFF",
                            "cx": "50",
                            "cy": "50",
                            "r": "50"
                          }
                        }
                      },
                      {
                        "data": {
                          "name": "circle",
                          "attr": {
                            "className": "inner",
                            "fill": "#000000",
                            "cx": "50",
                            "cy": "50",
                            "r": "25"
                          }
                        }
                      }
                    ]
                  }
                ]
              },
              {
                "data": {
                  "name": "image",
                  "attr": {
                    "xlinkHref": [
                      "iu",
                      [
                        "f",
                        "e.thumb.0"
                      ],
                      "thumb2"
                    ],
                    "_e": {
                      "xlinkHref": {
                        "1": {
                          "_type": "op"
                        },
                        "_type": "op",
                        "is": "exprValExt"
                      },
                      "mask": {
                        "2": {
                          "_type": "op"
                        },
                        "is": "exprValExt",
                        "_type": "op"
                      },
                      "width": {
                        "is": "exprValExt"
                      },
                      "height": {
                        "is": "exprValExt"
                      }
                    },
                    "mask": [
                      "+",
                      "url(#domain-mask-",
                      [
                        "get",
                        "attrs",
                        "_index"
                      ],
                      ")"
                    ],
                    "width": "100",
                    "height": "100"
                  }
                }
              }
            ]
          },
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "domain-name",
                "_e": {
                  "className": {
                    "is": "exprValExt"
                  }
                }
              }
            },
            "children": [
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "content": [
                      "f",
                      "domain.short_title"
                    ],
                    "_e": {
                      "content": {
                        "_type": "op",
                        "is": "exprValExt"
                      }
                    }
                  }
                },
                "children": []
              }
            ]
          }
        ]
      }
    ]
  }
}
*/
