import React from "react"
import useAttrs from "../hooks/useAttrs"
import { LazyImage } from "lib"
//, useDeferred
const Image = ({ info, state, dispatch, src, ...props }) => {
    //console.log("Image", info, src, args)
    //const srcVal = useDeferred(src)
    //if (!srcVal) return null
    /*
    const unmounted = useUnmounted()
    const srcRef = React.useRef(src)
    const [renderID, triggerRender] = React.useState(0)
    const waitFor = React.useCallback(async p => {
        const data = await p
        //console.log("IMAGERES:", data)
        if (unmounted.current) return
        srcRef.current = data
        triggerRender(renderID => renderID + 1 / 100)
    }, [])

    if (srcRef.current instanceof Promise) {
        waitFor(srcRef.current)
        return null
    }
    //console.log("IMAGE", info, args)
    //return null
    */
    //console.log("IMAGE", srcVal, info, args)
    const attrs = useAttrs(props)
    return <LazyImage src={src} {...attrs} />
}
export default Image
