import React from "react"

const MapperItem = ({ info, childrenFun, item, attr }) => {
    const newInfo = React.useMemo(
        () => ({
            ...info,
            context: {
                ...info.context,
                e: item.value,
                _e: {
                    ...(info.context._e ?? {}),
                    e: item.fieldType,
                },
            },
        }),
        [info, item]
    )
    return childrenFun(newInfo, attr)
}
const Mapper = ({ info, state, dispatch, target, children, ...attr }) => {
    //console.log("Mapper", target, info)
    return target.map((item, i) => (
        <MapperItem
            key={i}
            info={info}
            state={state}
            dispatch={dispatch}
            item={item}
            childrenFun={children}
            attr={attr}
        />
    ))
}

export default Mapper
