import React from "react"
const filterKeys = [
    "info",
    "state",
    "dispatch",
    "if",
    "content",
    "isContent",
    "renderChildren",
    "children",
    "display",
    "tooltip",
    "_e",
]

const useAttrs = props =>
    React.useMemo(
        () =>
            Object.keys(props)
                .filter(key => !filterKeys.includes(key))
                .reduce((acc, key) => {
                    acc[key] = props[key]
                    return acc
                }, {}),
        [props]
    )

export default useAttrs
