import React from "react"
import ReactDOM from "react-dom"
import { useUnmounted } from "lib"
//import Element from "./Element"
import Type from "lib/entity/type"
import Data from "lib/entity/data"
//import { capitalize } from "../util"
//import renderers from "../renderers"
import useElement from "../hooks/useElement"

const Tooltip = ({ info, tooltip, tooltipInfo, tooltipRef }) => {
    const [, triggerRender] = React.useState(0)
    const unmounted = useUnmounted()
    const Element = useElement(tooltipInfo)
    //console.log(tooltipInfo, info)
    React.useEffect(() => {
        if (unmounted.current) return
        const div = document.createElement("div")
        div.classList.add("tooltip")
        document.body.appendChild(div)
        tooltipRef.current = div
        triggerRender(state => (state + 1) % 100)
        return () => {
            //console.log("effect out", div)
            tooltipRef.current = null
            if (div) div.remove()
        }
    }, [tooltipRef, unmounted])
    const newInfo = React.useMemo(
        () => ({
            ...info,
            context: {
                ...info.context,
                e: tooltip,
                _e: {
                    ...info.context._e,
                    e: tooltipInfo,
                },
            },
        }),
        [info, tooltip, tooltipInfo]
    )
    if (!tooltipRef.current) return null
    //const Element = renderers[capitalize(realType)]
    if (!Element) return null
    //console.log("RENDER", tooltipRef.current, tooltip, tooltipInfo)
    return ReactDOM.createPortal(<Element info={newInfo} />, tooltipRef.current)
}

const useTooltip = (info, tooltipField) => {
    const tooltipRef = React.useRef()
    const [hover, setHover] = React.useState()
    //const hasTooltip = !!attrs.tooltip
    const [tooltipInfo, tooltipValue, newInfo] = React.useMemo(() => {
        if (!tooltipField) return null
        const toks = tooltipField.split(/\?*\./)
        //console.log(toks)
        if (toks[0] === "info" && toks[1] === "context") {
            const field = toks.slice(2).join(".")
            const t = Type.getType(info.context, "map")
            const tooltipInfo = Type.getKeyType(field, info.context, t)
            const realType = Type.realType(tooltipInfo)
            const value = Data.get(info.context, field, { parentType: t })
            //console.log(field, value, realType)
            const newInfo = {
                ...info,
                context: {
                    ...info.context,
                    e: value,
                    [realType]: value,
                    _e: {
                        ...info.context._e,
                        e: tooltipInfo,
                        [realType]: tooltipInfo,
                    },
                },
            }
            return [tooltipInfo, value, newInfo]
        }
        //console.log(tooltipField, toks)
        return [null, null, null]
        //if (!hasTooltip) return null
        /*const attrsInfo = Type.getType(attrs, "map")
        const tooltipInfo = Type.getKeyType("tooltip", attrs, attrsInfo)
        const realType = Type.realType(tooltipInfo)
        return [tooltipInfo, realType]*/
    }, [tooltipField, info])

    const Tag = React.useMemo(
        () =>
            tooltipValue && hover ? (
                <Tooltip
                    info={newInfo}
                    tooltip={tooltipValue}
                    tooltipInfo={tooltipInfo}
                    tooltipRef={tooltipRef}
                />
            ) : null,
        [hover, tooltipValue, tooltipInfo, newInfo]
    )

    const onMouseMove = React.useCallback(e => {
        //console.log("MOVE", e.clientY, e.clientX, tooltipRef.current)
        if (!tooltipRef.current) return
        tooltipRef.current.style.top = `${e.clientY}px`
        tooltipRef.current.style.left = `${e.clientX}px`
        //if (attrs.onMouseMove) attrs.onMouseMove(e)
    }, [])
    const onMouseOver = React.useCallback(e => {
        //console.log("HOVER")
        setHover(true)
        //if (attrs.onMouseOver) attrs.onMouseOver(e)
    }, [])
    const onMouseOut = React.useCallback(e => {
        //console.log("HOUT")
        setHover(false)
        //if (attrs.onMouseOut) attrs.onMouseOut(e)
    }, [])
    if (!tooltipValue) return [Tag, null]
    //console.log(attrs.tooltip)
    return [Tag, { onMouseOver, onMouseOut, onMouseMove }]
}
const WithTooltip = ({ info, tooltip, children }) => {
    const [renderTooltip, tprops] = useTooltip(info, tooltip)
    return (
        <>
            {children(tprops)}
            {renderTooltip}
        </>
    )
}
export default WithTooltip
