import React from "react"
import { registerForUpdates, unregisterForUpdates } from "./db"

const useDb = () => {
    const [, setRenderId] = React.useState(0)
    React.useEffect(() => {
        const id = registerForUpdates(() => {
            setRenderId(id => (id + 1) % 100)
        })
        return () => {
            unregisterForUpdates(id)
        }
    }, [])
    return typeof window === "undefined" ? global._db : window._db
}
export default useDb
