import React from "react"
import useAttrs from "ui/hooks/useAttrs"
import LazyImage from "lib/ui/LazyImage"

const SloganTop = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs

    const htmlAttrs = useAttrs(attrs)
    return (
        <div ref={domRef} {...htmlAttrs} elem-slogan-top="" className="slogan-top">
            <LazyImage
                src={{
                    name: "slogan-excellence-1.svg",
                    url: "/upload/element/exprVal/2021/4/26/slogan-excellence-1.svg",
                }}
                alt="slogan-excellence-1.svg"
                style={{ width: "180px", height: "27px" }}
                inline="1"
            />
        </div>
    )
}
export default SloganTop
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "className": "slogan-top"
      }
    },
    "children": [
      {
        "data": {
          "_e": {
            "name": {
              "_type": "img"
            }
          },
          "name": [
            {
              "name": "slogan-excellence-1.svg",
              "url": "/upload/element/exprVal/2021/4/26/slogan-excellence-1.svg"
            }
          ],
          "attr": {
            "style": {
              "width": "180px",
              "_e": {
                "width": {
                  "_type": {
                    "is": "string",
                    "label": "Text simplu",
                    "icon": "filled/title"
                  }
                },
                "height": {
                  "_type": {
                    "is": "string",
                    "label": "Text simplu",
                    "icon": "filled/title"
                  }
                }
              },
              "height": "27px"
            },
            "_e": {
              "style": {
                "_type": "map",
                "is": "exprValExt"
              },
              "inline": {
                "is": "exprValExt"
              }
            },
            "inline": "1"
          }
        }
      }
    ]
  }
}
*/
