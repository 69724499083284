import { getType } from "../type"
import { compose } from "../schema"
//import apply from "../apply"
import settings from "../basic_settings"
//import validators from "../validators"
//import converters from "../converters"
import { $settingType } from "../symbols"

const unset = (parent, parentType, key) => {
    //console.log("TYPE REMOVEKEY", key, parent, parentType)
    return [parent, [key]]
}

const join = (o1, o2, level) => {
    if (level === 0 || !o1 || typeof o2 !== "object") {
        return typeof o2 !== "object" ? o2 : JSON.parse(JSON.stringify(o2))
    }
    const key = Object.keys(o2)[0]
    //console.log("JOIN", level, o1, o2, key)
    let ret
    if (typeof key === "number")
        ret = [
            ...(key > 0 ? (o1 ?? []).slice(0, key) : []),
            join(o1?.[key], o2[key], level - 1),
            ...(key < (o1?.length ?? 0 - 1) ? (o1 ?? []).slice(key + 1) : []),
        ]
    else {
        let src = key === "_e" ? JSON.parse(JSON.stringify(o1 ?? {})) : o1 ?? {}
        ret = {
            ...src,
            [key]: join(src?.[key], o2[key], level - 1),
        }
    }
    //console.log("JOIN RET:", ret)

    return ret
}

const setter = (val, level) => (parent, parentType, key) => {
    //console.log("TYPE SETTER", parent, parentType, key, type, value, val, level)
    /*if (type.classes.indexOf("setting") >= 0) {
        if (parentType.classes.indexOf("entity") >= 0)
            return join(parent, { _c: { _e: { [key]: val } } }, level + 3)
        return setter({ _e: { [key]: val } }, level + 1)
    }*/
    if (parentType.classes.indexOf("setting") >= 0) {
        return setter({ [key]: val }, level + 1)
    }
    /*if (type.classes.indexOf("setting") >= 0 && parentType.classes.indexOf("entity") >= 0)
        return join(parent, { _c: { [key]: val } }, level + 2)*/
    ///^\d+$/.test(key) &&
    if (parentType.classes.indexOf("list") >= 0) return setter({ [key]: val }, level + 1)
    if (parentType.classes.indexOf("map") >= 0)
        return join(parent, { _e: { [key]: val } }, level + 2)
    return setter({ _e: { [key]: val } }, level + 2)
}
//join(parent?._e?.[key], val)
const set = (parent, parentType, key, type, value) => {
    //console.log("TYPE SET", parent, parentType, key, type, value)
    if (parentType.classes.indexOf("entity") >= 0) {
        if (key === "_e") return join(parent, { _c: value }, 2)
        return join(parent, { _c: { [key]: value } }, 2)
    }
    //if (parentType.classes.indexOf("setting") >= 0) return setter({ [key]: value }, 1)
    if (key === "_e") return setter(value, 1)
    return setter({ [key]: value }, 1)
}

const buildPath = (parent, parentType, key, path) => {
    //console.log("TYPE BUILDPATH", key, parent, parentType)
    let type = parent?.[$settingType]?.[key]
    if (typeof type === "undefined") {
        type = keyType(parent, parentType, key)
        if (!type) return null
        if (parent && typeof parent === "object") {
            if (!parent?.[$settingType] && Object.isExtensible(parent)) {
                Object.defineProperty(parent, $settingType, { value: {} })
            }
            if (parent[$settingType]) parent[$settingType][key] = type
        }
    }
    //console.log("TYPE BUILDPATH TYPE", type)
    if (!type) return null
    let value = type._sin ? parent?.[key] : parentType?.[key]
    if (key === "_id") {
        //console.log("_ID", value, value.toString())
        value = value ? value.toString() : value
    }
    const item = {
        parent,
        parentType,
        key,
        type,
        value,
    }
    return [...path, item]
}

const typeKeys = (o, type) => {
    //console.log("TYPE TYPEKEYS", o, type)
    return type
}
const keyType = (parent, parentType, key) => {
    //console.log("TYPE KEYTYPE", key, parent, parentType)
    //if (/^\d+$/.test(key))
    //    return getType(parentType?.[key], { is: "typeDef", classes: ["type", "setting"] })
    const setting = parentType?.settings?.[key]
    if (setting) {
        const keyDef = {
            ...(typeof setting === "string" ? { is: setting } : setting),
            classes: [...(setting.classes ?? []), "setting"],
        }
        const keyConfig = parentType.classes.includes("entity")
            ? parent?._c?._e?.[key]
            : parent?._e?._e?.[key]
        //console.log("SETTING", key, keyDef, keyConfig)
        let kD = keyConfig ? compose(keyDef, keyConfig) : keyDef
        const kC = parentType?._e?.[key]
        kD = kC ? compose(kD, kC) : kD
        return getType(kD._sin ? parent?.[key] : parentType?.[key], kD)
    }
    return parentType.classes.reduce((acc, className) => {
        if (acc) return acc
        const setting = settings[className]?.[key]
        if (!setting) return null
        //console.log(fieldName, setting)
        const keyDef = {
            ...(typeof setting === "string" ? { is: setting } : setting),
            classes: [...(setting.classes ?? []), "setting"],
        }
        const keyConfig = parentType.classes.includes("entity")
            ? parent?._c?._e?.[key]
            : parent?._e?._e?.[key]
        let kD = keyConfig ? compose(keyDef, keyConfig) : keyDef
        const kC = parentType?._e?.[key]
        kD = kC ? compose(kD, kC) : kD
        //console.log("SETTING", setting, key, keyDef, keyConfig)
        return getType(kD._sin ? parent?.[key] : parentType?.[key], kD)
    }, null)
}

const parseExpr = (e, value) => {
    const key = Object.keys(e)?.[0]
    const v = parseValue(e[key], value)

    switch (key) {
        case "not":
            return !v
        default:
            return v
    }
}

const parseValue = (v, value) => {
    if (v === "_") return value
    if (typeof v === "object") {
        return parseExpr(v, value)
    }
    return v
}
/*
const update = (parent, type, value, field, parentType) => {
    //console.log(parent, type, value, field, parentType)
    if (type.onChange) {
        if (type.onChange.set) {
            return type.onChange.set.reduce((acc, item) => {
                const key = Object.keys(item)?.[0]
                if (!key) return acc
                const val = parseValue(item[key], value)
                //console.log(key, val)
                return Data.set(acc, key, val, { parentType })
            }, parent)
        }
    }
    return parent
}*/
export default {
    keyType,
    typeKeys,
    //update,
    buildPath,
    set,
    unset,
}
