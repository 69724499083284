import React from "react"
import useAttrs from "ui/hooks/useAttrs"

import renderers from "ui/renderers"

const SectionIntro = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { Image, Fields } = renderers

    const htmlAttrs = useAttrs(attrs)
    return (
        <section
            ref={domRef}
            {...htmlAttrs}
            elem-section-intro=""
            className="intro"
            id={"section-" + attrs?._index}
        >
            <Image
                info={info}
                state={state}
                dispatch={dispatch}
                src={attrs?.bg?.[0]}
                bg="1"
                className="bg"
            />
            <div className="content">
                <h2 className="title">
                    <Fields
                        info={info}
                        state={state}
                        dispatch={dispatch}
                        field="attrs.title"
                        attrs={attrs}
                        isContent={true}
                    />
                </h2>
                <div className="text">
                    <Fields
                        info={info}
                        state={state}
                        dispatch={dispatch}
                        field="attrs.text"
                        attrs={attrs}
                        isContent={true}
                    />
                </div>
            </div>
        </section>
    )
}
export default SectionIntro
/*
{
  "layout": {
    "data": {
      "name": "section",
      "attr": {
        "className": "intro",
        "_e": {
          "className": {
            "is": "exprValExt"
          },
          "id": {
            "2": {
              "_type": "op"
            },
            "_type": "op",
            "is": "exprValExt"
          }
        },
        "id": [
          "+",
          "section-",
          [
            "get",
            "attrs",
            "_index"
          ]
        ]
      }
    },
    "children": [
      {
        "data": {
          "name": "Image",
          "attr": {
            "src": [
              "get",
              "attrs",
              "bg.0"
            ],
            "_e": {
              "src": {
                "_type": "op",
                "is": "exprValExt"
              },
              "bg": {
                "is": "exprValExt"
              }
            },
            "bg": "1",
            "className": "bg"
          }
        }
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "content"
          }
        },
        "children": [
          {
            "data": {
              "name": "h2",
              "attr": {
                "className": "title",
                "_e": {
                  "className": {
                    "is": "exprValExt"
                  },
                  "content": {
                    "_type": "op"
                  }
                },
                "content": [
                  "get",
                  "attrs",
                  "title"
                ]
              }
            }
          },
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "text",
                "content": [
                  "get",
                  "attrs",
                  "text"
                ],
                "_e": {
                  "content": {
                    "_type": "op"
                  }
                }
              }
            }
          }
        ]
      }
    ]
  }
}
*/
