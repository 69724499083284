import React from "react"
import useAttrs from "../hooks/useAttrs.js"
const String = ({ info, state, dispatch, content, tag, isContent, ...attr }) => {
    const ref = React.useRef()
    const attrs = useAttrs(attr)
    const s = content ?? info?.context?.e ?? ""
    //console.log(info, content, s, attr)
    if (isContent) return s
    const Tag = tag ?? "div"
    return (
        <Tag ref={ref} elem-string="" {...attrs}>
            {s}
        </Tag>
    )
}
export default String
