import NodeTransition from "./NodeTransition"
import HTML from "./Html"
import Dialog from "./Dialog"
import Image from "./Image"
import ImageList from "./ImageList"
import Icon from "./Icon"
import Link from "./Link"
import Carousel from "./Carousel"
import Track from "./Track"
import ItemTransition from "./ItemTransition"
import ElemWidget from "./ElemWidget"
import LayoutWidget from "./LayoutWidget"
import Slider from "./Slider"
import Portal from "./Portal"
import Navigator from "./Navigator"
import WithTooltip from "./WithTooltip"
import Fields from "./Fields"
import Mapper from "./Mapper"
import customRenderers from "conf/renderers"
import primitiveRenderers from "../primitive"
//import elements from "../../elements"

const renderers = {
    NodeTransition,
    Image,
    ImageList,
    Icon,
    Carousel,
    Track,
    Navigator,
    HTML,
    Link,
    Dialog,
    ItemTransition,
    ElemWidget,
    LayoutWidget,
    Slider,
    Portal,
    WithTooltip,
    Fields,
    Mapper,
    ...primitiveRenderers,
    //...elements,
    ...customRenderers,
}

export default renderers
