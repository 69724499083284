import React from "react"
import { Helmet } from "react-helmet-async"
import { C, useImage, Entity } from "lib"
import { ld } from "conf/ld"
import useFinder from "lib/db/useFinder"

const locales = {
    ro: "ro_RO",
    en: "en_US",
    de: "de_DE",
}
//const JsonLd = ({ data }) =>
const reduceLdMap = ldMap =>
    ldMap.reduce((acc, entry) => {
        if (typeof entry.value === "string") return { ...acc, [entry.key]: entry.value }
        if (Array.isArray(entry.value)) {
            if (entry._e?.value?._type === "ldList") return { ...acc, [entry.key]: entry.value }
            return { ...acc, [entry.key]: reduceLdMap(entry.value) }
        }
        return acc
    }, {})

const useLd = (node, language) => {
    //const [ldQuery, setLdQuery] = React.useState()
    const [ldRes, ldQuery] = React.useMemo(() => {
        if (!node) return [null, null]
        const ldConf = ld?.[node.type]
        if (!ldConf) return [null, null]
        if (typeof ldConf === "function") {
            //setLdQuery(null)
            return [ldConf(node, language), null]
        }
        return [
            null,
            {
                collection: "ld",
                query: ldConf,
            },
        ]
        //return null
    }, [node, language])
    const [ldResults] = useFinder(ldQuery)

    return React.useMemo(() => {
        if (ldResults) {
            const ldMap = ldResults?.[0]?.map
            if (!ldMap) return null
            return {
                "@context": "http://schema.org",
                ...reduceLdMap(ldMap),
            }
        }
        if (!ldRes) return null
        return { "@context": "http://schema.org", ...ldRes }
    }, [ldRes, ldResults])
}

const SEO = ({ location, node, language }) => {
    //const { location } = useHistory()
    //console.log(location)
    const url = `${C.BASE}${location.pathname}`
    //typeof window === "undefined" ? `${C.BASE}${global.location}` : window.location.href

    let title = Entity.get(node, "title", { language })
    title = C.WEBSITE_NAME + (title ? ` - ${title}` : "")
    const seoTitle = Entity.get(node, "_seo.title", { language }) || title

    const locale = locales?.[language] ?? "en_US"
    const description = Entity.get(node, "_seo.description", { language }) ?? ""
    const ogImage = Entity.get(node, "_seo.image") ?? Entity.get(node, "thumb")
    const [imageUrl] = useImage(ogImage?.[0], "og")
    //if (image) image = imgPath(image, "thumb")
    let meta = [
        { name: "og:url", content: url },
        { name: "og:type", content: "article" },
        { name: "og:title", content: seoTitle },
        { name: "og:description", content: description },
        { name: "og:locale", content: locale },
        { name: "twitter:url", content: url },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
        { name: "description", content: description },
    ]
    if (imageUrl)
        meta.push({
            name: "og:image",
            content: imageUrl,
        })

    const htmlAttributes = { lang: language ?? "ro" }

    let link
    if (C.LANGUAGES) {
        link = [
            ...C.LANGUAGES.map(lang => ({
                rel: "alternate",
                hreflang: lang,
                href: C.BASE + Entity.getPath(node, lang) + ".html",
            })),
            {
                rel: "alternate",
                hreflang: "x-default",
                href: C.BASE + (node?.alias?.[0] ?? Entity.getPath(node, C.LANGUAGES[0]) + ".html"),
            },
        ]
    }
    const ld = useLd(node, language)
    //const ld = null
    //const script = ld ? { type: "application/ld+json", innerHTML: JSON.stringify(ld) } : undefined
    //console.log(ld)
    /*const ld = {
        "@context": "http://schema.org",
        "@type": "WebSite",
        name: seoTitle,
        url,
        description,
        image,
    }*/
    //if (image) ld.image = image
    //console.log(node)
    if (!ld) return <Helmet title={title} meta={meta} htmlAttributes={htmlAttributes} link={link} />
    return (
        <Helmet title={title} meta={meta} htmlAttributes={htmlAttributes} link={link}>
            <script type="application/ld+json">{JSON.stringify(ld)}</script>
        </Helmet>
    )
}
export default SEO
