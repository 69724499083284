import React from "react"
import NodeWrap from "./NodeWrap"

const Homepage = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs

    return (
        <NodeWrap
            {...attrs}
            elem-homepage=""
            info={info}
            state={state}
            dispatch={dispatch}
            type-homepage=""
        />
    )
}
export default Homepage
/*
{
  "layout": {
    "data": {
      "name": "NodeWrap",
      "attr": {}
    },
    "children": []
  }
}
*/
