import React from "react"
import { Entity, LazyImage, useSubscription } from "lib"
//import Container from "./Container"
import Portal from "./Portal"
//import elements from "elements"
import useAttrs from "../hooks/useAttrs"
import useElement from "../hooks/useElement"
//const capitalize = str => str[0].toUpperCase() + str.slice(1)

const ImageList = attrs => {
    const { info, state, dispatch } = attrs
    const htmlAttrs = useAttrs(attrs)
    //console.log("IMAGE LIST", info, attrs)
    const [lb, setLB] = React.useState()
    //const carouselInfo = info.typeInfo._carousel
    let val = info.context.e
    const typeInfo = info.context._e.e
    /*const localDispatch = React.useCallback(action => {
        console.log(action)
        }, [])*/
    useSubscription("closeLightbox", () => {
        setLB(false)
    })
    const [lightboxType, lightboxInfo] = React.useMemo(() => {
        if (!attrs.lightbox) return [null, null]
        const attrsInfo = Entity.getType(attrs, { is: "map", values: "exprValExt" })
        const lightboxType = Entity.getKeyType("lightbox", attrs, attrsInfo)
        return [
            lightboxType,
            {
                ...attrs.info,
                context: {
                    ...attrs.info.context,
                    e: attrs.lightbox,
                    _e: {
                        ...attrs.info.context._e,
                        e: lightboxType,
                    },
                },
            },
        ]
    }, [attrs])
    const handleLightbox = React.useCallback(() => {
        setLB(true)
    }, [])
    const imgProps = React.useMemo(() => {
        let props = {}
        if (typeInfo.bg) props.bg = 1
        if (!attrs.lightbox) return props
        return { ...props, onClick: handleLightbox }
    }, [attrs, handleLightbox, typeInfo])
    let Tag = React.Fragment
    let tagArgs = {}
    let imgArgs = htmlAttrs
    if (typeInfo._wrap) {
        Tag = "div"
        const { domRef, inline, _e, ...rest } = htmlAttrs
        tagArgs = { ref: domRef, ...rest }
        imgArgs = { inline, _e }
    }
    const Element = useElement(lightboxType)
    //console.log(args)
    if (!val || val.length === 0) return null
    //console.log("IMAGELIST", newInfo)

    /*if (carouselInfo) {
        return (
            <Container tag={Carousel} info={info} {...args}>
                <Track>
                    {val.map((img, i) => (
                        <LazyImage key={i} src={img} data-index={i} {...imgProps} />
                    ))}
                </Track>
                {carouselInfo.arrows !== false && <Arrows />}
                {carouselInfo.navigator !== false && <Navigator />}
            </Container>
        )
    }*/
    //console.log(info)
    return (
        <Tag {...tagArgs}>
            {val.map((src, i) => (
                <LazyImage key={i} src={src} {...imgArgs} {...imgProps} />
            ))}
            {lb && Element && (
                <Portal>
                    <Element info={lightboxInfo} state={state} dispatch={dispatch} />
                </Portal>
            )}
        </Tag>
    )
}
export default ImageList
