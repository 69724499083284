import React from "react"
import NodeWrap from "./NodeWrap"

const Page = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs

    return (
        <NodeWrap
            {...attrs}
            elem-page=""
            info={info}
            state={state}
            dispatch={dispatch}
            type-page=""
        />
    )
}
export default Page
/*
{
  "layout": {
    "data": {
      "name": "NodeWrap"
    }
  }
}
*/
