import React from "react"
import useSubscription from "lib/hooks/useSubscription"

import renderers from "ui/renderers"

const SiteBg = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { ItemTransition, Image } = renderers
    const [node] = useSubscription("node")

    return (
        <ItemTransition
            {...attrs}
            elem-site-bg=""
            info={info}
            state={state}
            dispatch={dispatch}
            itemKey={node?.bg?.[0]?.url}
        >
            <Image
                info={info}
                state={state}
                dispatch={dispatch}
                src={node?.bg?.[0]}
                bg="1"
                className="img-bg"
            />
        </ItemTransition>
    )
}
export default SiteBg
/*
{
  "context": {
    "subscriptions": [
      "node"
    ]
  },
  "layout": {
    "data": {
      "name": "ItemTransition",
      "attr": {
        "itemKey": [
          "get",
          "node",
          "bg.0.url"
        ],
        "_e": {
          "itemKey": {
            "is": "exprValExt",
            "_type": "op"
          }
        }
      }
    },
    "children": [
      {
        "data": {
          "name": "Image",
          "attr": {
            "src": [
              "get",
              "node",
              "bg.0"
            ],
            "_e": {
              "src": {
                "_type": "op",
                "is": "exprValExt"
              },
              "bg": {
                "is": "exprValExt"
              }
            },
            "bg": "1",
            "className": "img-bg"
          }
        }
      }
    ]
  }
}
*/
