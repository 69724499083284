import React from "react"
import useAttrs from "ui/hooks/useAttrs"
import useSubscription from "lib/hooks/useSubscription"

const Overlay = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs

    const [domainHover] = useSubscription("domainHover")

    const htmlAttrs = useAttrs(attrs)
    return (
        <div
            ref={domRef}
            {...htmlAttrs}
            elem-overlay=""
            id="overlay"
            className={domainHover ? "domains-hover" : undefined}
        />
    )
}
export default Overlay
/*
{
  "context": {
    "subscriptions": [
      "domainHover"
    ]
  },
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "id": "overlay",
        "_e": {
          "id": {
            "is": "exprValExt"
          },
          "className": {
            "1": {
              "_type": "op"
            },
            "_type": "op"
          }
        },
        "className": [
          "if",
          [
            "f",
            "domainHover"
          ],
          "domains-hover"
        ]
      }
    }
  }
}
*/
