import React from "react"
import { C, History, useHistory, useSubscription } from "lib"
//import Element from "../display/Element"
//import { retrieveStore } from "../display/renderers/NodeTransition"
import { updateDb } from "lib/db/db"
//const Site = React.lazy(() => import("elements").then(module => ({ default: module.Site })))
import { Site } from "elements"
/*
const useDynamicScript = url => {
    const [ready, setReady] = React.useState(false)
    const [failed, setFailed] = React.useState(false)

    React.useEffect(() => {
        if (!url) return

        const el = document.createElement("script")
        el.src = url
        el.type = "text/javascript"
        el.async = true

        setReady(false)
        setFailed(false)

        el.onload = () => {
            console.log(`Loaded, ${url}`)
            setReady(true)
        }
        el.onerror = () => {
            console.log(`Error loading, ${url}`)
            setReady(false)
            setFailed(true)
        }
        document.head.appendChild(el)
        return () => {
            console.log(`Dyn script removed ${url}`)
            document.head.removeChild(el)
        }
    }, [url])
    return { ready, failed }
}
const RemoteComponent = ({ url, scope, module, ...props }) => {
    const { ready, failed } = useDynamicScript(url)
    if (!ready) return <h2>Loading...</h2>
    if (failed) return <h2>Failed loading...</h2>
    const Component = React.lazy(
        () =>
            new Promise(moduleResolve => {
                const react = require("react")
                const reactDom = require("react-dom")
                const lib = require("lib")
                const shareScope = {
                    react: {
                        [react.version]: {
                            get: () =>
                                new Promise(sharedModuleResolve => {
                                    sharedModuleResolve(() => react)
                                }),
                            loaded: true,
                            from: "webpack4",
                        },
                    },
                    lib: {
                        [lib.version]: {
                            get: () =>
                                new Promise(sharedModuleResolve => {
                                    sharedModuleResolve(() => lib)
                                }),
                            loaded: true,
                            from: "webpack4",
                        },
                    },
                    "react-dom": {
                        [reactDom.version]: {
                            get: () =>
                                new Promise(sharedModuleResolve => {
                                    sharedModuleResolve(() => reactDom)
                                }),
                            loaded: true,
                            from: "webpack4",
                        },
                    },
                }
                new Promise(containerResolve => {
                    containerResolve(window[scope].init(shareScope))
                }).then(() => {
                    console.log(shareScope)
                    window[scope].get(module).then(factory => {
                        moduleResolve(factory())
                    })
                })
            })
    )

    return (
        <React.Suspense fallback="Loading...">
            <Component {...props} />
        </React.Suspense>
    )
}
*/
const SiteWrapper = () => {
    //const [loadingStore, setLoadingStore] = React.useState()
    const [user, setUser] = React.useState()
    const [isShell, setIsShell] = React.useState(History.getLocation().pathname === "/shell")
    useHistory(location => {
        //console.log("Site isShell", location.pathname === "/shell")
        setIsShell(location.pathname === "/shell")
    })
    //console.log("RENDER SITE")
    const handleSub = React.useCallback((topic, arg) => {
        //if (url === location.pathname) return
        //console.log("SITE openurl", url)
        switch (topic) {
            case "openURL":
                History.push(arg)
                return
            default:
                setUser(arg)
        }
    }, [])
    useSubscription(["openURL", "user"], handleSub)
    const info = React.useMemo(() => {
        //console.log("SITE NEW INFO")
        return {
            user,
            context: { user },
        }
    }, [user])
    /*React.useMemo(() => {
        if (typeof window === "undefined" || window.isHydrating) return
        const pathname = window.location.pathname
        if (pathname === "/shell") return
        setLoadingStore(true)
        retrieveStore(pathname).then(() => {
            //console.log(data)
            setLoadingStore(false)
        })
    }, [])*/
    React.useEffect(() => {
        if (isShell) return
        setTimeout(() => {
            requestIdleCallback(() => {
                navigator?.serviceWorker?.controller?.postMessage({
                    type: "SITE-LOADED",
                    payload: window.location.pathname,
                })
                if (C.isLocal) updateDb()
            })
        }, 5000)
    }, [isShell])
    if (isShell) return null
    return <Site info={info} />
}
export default SiteWrapper
