import React from "react"
import { Entity } from "lib"
import elements from "elements"
//import { $keyOrder } from "../../entity/symbols"
const capitalize = str => str[0].toUpperCase() + str.slice(1)

const LayoutWidget = ({ info, state, dispatch, ...args }) => {
    console.log("LAYOUT", info, args)
    //console.log(info.value.layout, Entity.getKeyType("layout", info.value, info.typeInfo), args) //info, state, args)
    /*return (
        <Node
            info={info}
            domRef={domRef}
            node={info.value?.layout}
            nodeInfo={Entity.getKeyType("layout", info.value, info.typeInfo)}
            nodePath={[0]}
            wrapper={true}
            state={state}
            dispatch={dispatch}
            attrs={args}
        />
        )*/
    return null
}
export default LayoutWidget
