import React from "react"
import { Entity } from "lib"
import elements from "elements"
const capitalize = str => str[0].toUpperCase() + str.slice(1)

const Slider = ({ domRef, info, state, dispatch, children, ...args }) => {
    const [active, setActive] = React.useState(0)
    const items = React.useMemo(() => {
        return args.items ?? []
    }, [args])
    const timeout = React.useMemo(() => info?.value?.timeout ?? args?.timeout ?? 1000, [info, args])
    React.useEffect(() => {
        const t = setInterval(() => {
            //if(unmounted.current) return
            setActive(active => (active < items.length - 1 ? active + 1 : 0))
        }, timeout)
        return () => {
            clearInterval(t)
        }
    }, [items, timeout])
    const [context, newInfo] = React.useMemo(() => {
        const context = {
            ...info.context,
            activeSlide: items?.[active],
            _e: {
                ...info.context._e,
                activeSlide: { is: items?.[active]?.type },
            },
        }
        return [context, { ...info, context }]
    }, [active, items, info])
    if (!children) {
        const keys = Entity.keyOrder(info.typeInfo).filter(
            key => info.typeInfo.keys[key].keyType === "dynamic"
        )
        //console.log(keys, newInfo, args)
        //console.log(info, element, props, itemAttrs)
        return (
            <>
                {keys.map((key, i) => {
                    const Element = elements[capitalize(info.typeInfo.keys[key].is)]
                    if (!Element) return null
                    return (
                        <Element
                            key={i}
                            info={newInfo}
                            state={state}
                            dispatch={dispatch}
                            fieldName={key}
                            attrs={args}
                        />
                    )
                })}
            </>
        )
    }
    return children(context, state, dispatch)
    //return null
    /*const [active, setActive] = React.useState(0)
    const t = React.useRef()
    //console.log(args, info)
    //const result = useSelection(args)
    const [results, status, total] = useSelection(args, info, state, dispatch)
    React.useEffect(() => {
        const t = setInterval(() => {
            //if(unmounted.current) return
            setActive(active => (active < results.length - 1 ? active + 1 : 0))
        }, 10000)
        return () => {
            clearInterval(t)
        }
    }, [results])
    const [newInfo, newState] = React.useMemo(() => {
        const newState = {
            ...(state ?? {}),
            items: results,
            active: results?.[active],
            nSlides: results?.length ?? 0,
        }
        const newInfo = {
            ...info,
            context: {
                ...info.context,
                state: newState,
                items: results,
                active: results?.[active],
                nSlides: results?.length ?? 0,
            },
        }
        return [newInfo, newState]
    }, [info, state, results, active])
    const keys = Entity.keyOrder(info.typeInfo).filter(
        key => info.typeInfo.keys[key].keyType === "dynamic"
    )
    //console.log(keys, info, args)
    //console.log(info, element, props, itemAttrs)
    return (
        <>
            {keys.map((key, i) => (
                <Element
                    key={i}
                    info={newInfo}
                    state={newState}
                    dispatch={dispatch}
                    fieldName={key}
                    attrs={args}
                />
            ))}
        </>
    )*/
}

export default Slider
