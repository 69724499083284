import React, { useRef, useState, useEffect, useCallback } from "react"
import { History, useScreen } from "lib"
import NodeManager from "./node/NodeManager"

const Location = () => {
    const [location, setLocation] = useState(History.getLocation())
    //console.log("RENDER LOCATION", location)
    const handleLocationChange = useCallback((pathname, search, hash, language, sanitized) => {
        //console.log("LOCATION CHANGE", pathname)
        setLocation({ pathname, search, language, sanitized })
    }, [])

    const screenClasses = useRef()
    useScreen((w, h, mq, o) => {
        if (typeof window === "undefined") return
        const mobile = w < 400 || h < 400 ? "mobile" : "not-mobile"
        //console.log(w, h)
        if (screenClasses.current) {
            if (
                screenClasses.current.mq === mq &&
                screenClasses.current.o === o &&
                screenClasses.current.mobile === mobile
            )
                return
            document.body.classList.remove(...screenClasses.current)
            screenClasses.current = [mq, o, mobile, "site-loaded"]
            document.body.classList.add(...screenClasses.current)
            /*const newScreenClasses = `${mq} ${o} ${}`
              if (screenClasses.current === newScreenClasses) return
              screenClasses.current = newScreenClasses
              document.body.className = screenClasses.current*/
            document.body.style.setProperty("--screen-ratio", w / h)
            //document.body.style.setProperty("--w", w)
        } else {
            screenClasses.current = document.body.classList
            //document.body.classList.remove(...document.body.classList)
        }
    })

    useEffect(() => {
        History.addLocationListener(handleLocationChange)
        return () => History.removeLocationListener(handleLocationChange)
    }, [handleLocationChange])
    /*useEffect(() => {
        document.body.classList.add("site-loaded")
    }, [])*/
    //console.log(location.search)
    return <NodeManager location={location} />
}
export default Location
