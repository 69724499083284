import React from "react"
import DomainTeaser from "./DomainTeaser"
import useAttrs from "ui/hooks/useAttrs"
import { publish } from "lib/hooks/useSubscription"

import Select from "ui/prerenderers/Select"

const Domains = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs

    const htmlAttrs = useAttrs(attrs)
    const handler_0_0_onMouseOver = React.useCallback(() => {
        publish("domainHover", true)
    }, [])
    const handler_0_0_onMouseOut = React.useCallback(() => {
        publish("domainHover", false)
    }, [])
    return (
        <div ref={domRef} {...htmlAttrs} elem-domains="" className="domains-outer">
            <Select info={info} entityType="domain">
                {info => (
                    <div
                        className="domains-wrap"
                        style={{ "--n-domains": info.context.selectTotal }}
                        onMouseOver={handler_0_0_onMouseOver}
                        onMouseOut={handler_0_0_onMouseOut}
                    >
                        <div className="domains">
                            {info.context.selectResults?.map((e, i) => {
                                const newInfo = {
                                    ...info,
                                    context: { ...info.context, e, domain: e },
                                }
                                return (
                                    <DomainTeaser
                                        key={i}
                                        info={newInfo}
                                        state={state}
                                        dispatch={dispatch}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )}
            </Select>
        </div>
    )
}
export default Domains
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "className": "domains-outer",
        "_e": {
          "className": {
            "is": "exprValExt"
          }
        }
      }
    },
    "children": [
      {
        "data": {
          "name": {
            "entityType": "domain"
          },
          "attr": {},
          "_e": {
            "name": {
              "_type": "select"
            }
          }
        },
        "children": [
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "domains-wrap",
                "_e": {
                  "className": {
                    "is": "exprValExt"
                  },
                  "style": {
                    "_type": "map",
                    "is": "exprValExt"
                  },
                  "onMouseOver": {
                    "2": {
                      "_type": "bool"
                    },
                    "_type": "action",
                    "is": "exprValExt"
                  },
                  "onMouseOut": {
                    "2": {
                      "_type": "bool"
                    },
                    "_type": "action",
                    "is": "exprValExt"
                  }
                },
                "style": {
                  "--n-domains": [
                    "f",
                    "selectTotal"
                  ],
                  "_e": {
                    "--n-domains": {
                      "is": "expr",
                      "_type": "op"
                    }
                  }
                },
                "onMouseOver": [
                  "sendMessage",
                  "domainHover",
                  true
                ],
                "onMouseOut": [
                  "sendMessage",
                  "domainHover",
                  false
                ]
              }
            },
            "children": [
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "className": "domains"
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "op"
                        }
                      },
                      "name": [
                        "f",
                        "selectResults"
                      ],
                      "attr": {
                        "display": "teaser"
                      }
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
*/
