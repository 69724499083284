const types = {
    // NODES

    homepage: {
        keys: {
            bg: { is: "img", label: "Fundal" },
        },
    },
    page: {
        keys: {
            body: { is: "html", label: "Conținut" },
            bg: { is: "img", label: "Fundal" },
        },
    },
    domain: {
        is: "node",
        label: "Domeniu",
        keys: {
            title: { is: "text", label: "Titlu" },
            subtitle: { is: "text", label: "Subtitlu" },
            short_title: { is: "text", label: "Titlu scurt" },
            bg: { is: "img", label: "Fundal", region: "Bg" },
            thumb: { is: "img", label: "Miniatura", region: "Teaser" },
        },
    },
    section: {
        is: "record",
        label: "Secțiune",
        classes: ["widget"],
        keys: {
            title: { is: "text", label: "Titlu" },
            intro: { is: "block", label: "Intro" },
            teaser: { is: "block", label: "Teaser" },
            content: { is: "block", label: "Conținut" },
        },
    },

    // TYPES
    block: {
        label: "Container",
        icon: "faBoxOpen",
        is: "map",
        isBlock: true,
        classes: ["widget"],
    },

    img: {
        icon: "filled/photo",
        settings: {
            lightbox: { is: "bool", label: "Lightbox" },
            _wrap: { is: "bool", label: "Wrap" },
        },
        renderer: "ImageList",
        _nowrap: true,
    },
    button: {
        label: "Buton",
        icon: "outlined/smart_button",
        dynamic: true,
        is: "record",
        classes: ["widget"],
        keys: {
            label: { is: "text", label: "Etichetă" },
            message: { is: "text", label: "Mesaj", _t: false },
        },
        renderer: "Button",
    },

    openURL: {
        is: "string",
        label: "Deschide URL",
    },
    domainHover: {
        is: "bool",
        label: "Domain Link Hover",
    },
    /*tooltip: {
        is: "layoutWidget",
        label: "Tooltip",
        //renderer: "Tooltip",
    },*/
}
export default types
