import React from "react"
import NodeWrap from "./NodeWrap"
import { fieldsRest } from "ui/utilFields"

import renderers from "ui/renderers"

const Layout_3_0_0 = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { Link, WithTooltip } = renderers

    return (
        <WithTooltip info={info} tooltip="info.context.item?.title">
            {tooltipProps => (
                <Link
                    {...attrs}
                    elem-map-item=""
                    info={info}
                    state={state}
                    dispatch={dispatch}
                    {...tooltipProps}
                    to={"#section-" + info.context.itemIndex}
                    type-domain=""
                >
                    <div />
                </Link>
            )}
        </WithTooltip>
    )
}

const Domain = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { Fields, Mapper } = renderers

    return (
        <NodeWrap
            {...attrs}
            elem-domain=""
            info={info}
            state={state}
            dispatch={dispatch}
            type-domain=""
        >
            <div field-title="">
                <h1 data-title={info.context.e?.title}>
                    <Fields
                        info={info}
                        state={state}
                        dispatch={dispatch}
                        field="e.title"
                        attrs={attrs}
                        isContent={true}
                    />
                </h1>
            </div>
            <Fields
                info={info}
                state={state}
                dispatch={dispatch}
                field="e.subtitle"
                attrs={attrs}
                isContent={undefined}
            />
            <Fields
                info={info}
                state={state}
                dispatch={dispatch}
                type="_rest_"
                parent="e"
                attrs={attrs}
                isContent={undefined}
            />
            <div
                className="tracker"
                style={{ "--tracker-n": (fieldsRest(info.context, "e") ?? []).length }}
            >
                <div className="tracker-in">
                    <Mapper
                        info={info}
                        state={state}
                        dispatch={dispatch}
                        target={fieldsRest(info.context, "e")}
                    >
                        {info => <Layout_3_0_0 info={info} state={state} dispatch={dispatch} />}
                    </Mapper>
                </div>
            </div>
        </NodeWrap>
    )
}
export default Domain
/*
{
  "name": "default",
  "layout": {
    "data": {
      "name": "NodeWrap"
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "field-title": ""
          }
        },
        "children": [
          {
            "data": {
              "name": "h1",
              "attr": {
                "content": [
                  "f",
                  "e.title"
                ],
                "_e": {
                  "content": {
                    "_type": "op",
                    "is": "exprValExt"
                  },
                  "data-title": {
                    "_type": "op"
                  }
                },
                "data-title": [
                  "f",
                  "e.title"
                ]
              }
            }
          }
        ]
      },
      {
        "data": {
          "name": [
            "f",
            "e.subtitle"
          ],
          "attr": {
            "id": [
              "+",
              "sub-",
              [
                "get",
                "attrs",
                "field"
              ]
            ],
            "_e": {
              "id": {
                "2": {
                  "_type": "op"
                },
                "_type": "op",
                "is": "exprValExt"
              }
            }
          },
          "_e": {
            "name": {
              "_type": "op"
            }
          }
        }
      },
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e._rest_"
          ]
        }
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "tracker",
            "_e": {
              "className": {
                "is": "exprValExt"
              },
              "style": {
                "_type": "map",
                "is": "exprValExt"
              }
            },
            "style": {
              "--tracker-n": [
                "len",
                [
                  "f",
                  "e._rest_"
                ]
              ],
              "_e": {
                "--tracker-n": {
                  "1": {
                    "_type": "op"
                  },
                  "_type": "op"
                }
              }
            }
          }
        },
        "children": [
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "tracker-in"
              }
            },
            "children": [
              {
                "data": {
                  "_e": {
                    "name": {
                      "1": {
                        "_type": "op"
                      },
                      "2": {
                        "_type": "layoutWidget"
                      },
                      "_type": "op"
                    }
                  },
                  "name": [
                    "map",
                    [
                      "f",
                      "e._rest_"
                    ],
                    {
                      "layout": {
                        "data": {
                          "name": "Link",
                          "attr": {
                            "to": [
                              "+",
                              "#section-",
                              [
                                "f",
                                "itemIndex"
                              ]
                            ],
                            "_e": {
                              "to": {
                                "2": {
                                  "_type": "op"
                                },
                                "_type": "op",
                                "is": "exprValExt"
                              },
                              "tooltip": {
                                "_type": "op",
                                "is": "exprValExt"
                              }
                            },
                            "tooltip": [
                              "get",
                              "item",
                              "title"
                            ]
                          }
                        },
                        "children": [
                          {
                            "data": {
                              "name": "div",
                              "attr": {}
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ]
  }
}
*/
