import React from "react"
import SiteBg from "./SiteBg"
import Overlay from "./Overlay"
import Domains from "./Domains"
import LogoWrap from "./LogoWrap"
import SloganTop from "./SloganTop"
import Menu from "./Menu"
import useAttrs from "ui/hooks/useAttrs"

import renderers from "ui/renderers"

const Site = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { NodeTransition } = renderers

    const htmlAttrs = useAttrs(attrs)
    return (
        <div ref={domRef} {...htmlAttrs} elem-site="">
            <SiteBg info={info} state={state} dispatch={dispatch} />
            <main>
                <NodeTransition
                    info={info}
                    state={state}
                    dispatch={dispatch}
                    main-inner=""
                    appear={true}
                    enter={true}
                />
            </main>
            <Overlay info={info} state={state} dispatch={dispatch} />
            <Domains info={info} state={state} dispatch={dispatch} />
            <LogoWrap info={info} state={state} dispatch={dispatch} />
            <SloganTop info={info} state={state} dispatch={dispatch} />
            <Menu info={info} state={state} dispatch={dispatch} />
        </div>
    )
}
export default Site
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {}
    },
    "children": [
      {
        "data": {
          "name": "SiteBg"
        }
      },
      {
        "data": {
          "name": "main"
        },
        "children": [
          {
            "data": {
              "name": "NodeTransition",
              "attr": {
                "main-inner": "",
                "_e": {
                  "main-inner": {
                    "is": "exprValExt"
                  },
                  "appear": {
                    "is": "exprValExt",
                    "_type": "bool"
                  },
                  "enter": {
                    "is": "exprValExt",
                    "_type": "bool"
                  }
                },
                "appear": true,
                "enter": true
              }
            }
          }
        ]
      },
      {
        "data": {
          "name": "Overlay",
          "attr": {}
        }
      },
      {
        "data": {
          "name": "Domains"
        }
      },
      {
        "data": {
          "name": "LogoWrap"
        }
      },
      {
        "data": {
          "name": "SloganTop"
        }
      },
      {
        "data": {
          "name": "Menu"
        }
      }
    ]
  }
}
*/
