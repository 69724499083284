import React from "react"
import { FaIcon } from "lib"

const Icon = ({ info, state, dispatch, ...args }) => {
    //console.log("ICON", info, args)
    const icon = args.icon
    return <FaIcon icon={icon} {...args} />
    //return <LazyImage {...args} />
}
export default Icon
