import React from "react"
import Type from "lib/entity/type"
import Data from "lib/entity/data"
import { $keyOrder } from "lib/entity/symbols"
//import renderers from "./index"
//import { capitalize } from "../util"
import getElement from "../getElement"
import { nameAttr } from "ui/util"

const Field = ({ info, fieldData, state, dispatch, attrs, isContent }) => {
    const { typeName, value, fieldType, fieldName, tag } = fieldData
    const [newInfo, args] = React.useMemo(
        () => [
            {
                ...info,
                context: {
                    ...(info.context ?? {}),
                    e: value,
                    [typeName]: value,
                    [typeName]: value,
                    _e: {
                        ...(info.context?._e ?? {}),
                        e: fieldType,
                        [typeName]: fieldType,
                    },
                },
            },
            {
                [nameAttr("type", typeName)]: "",
                [nameAttr("field", fieldName)]: "",
            },
        ],
        [info, typeName, value, fieldType, fieldName]
    )
    //if (tagName === "String") return value
    //if (!renderers[tag]) return null
    //const Tag = renderers[tag]
    if (!tag) {
        console.log(fieldData, info, attrs)
        return null
    }

    const Tag = tag

    return <Tag info={newInfo} state={state} dispatch={dispatch} isContent={isContent} {...args} /> // {...attrs}
}
//const getVal = (context, field) => field.split(".").reduce((acc, item) => acc?.[item], context)

const getField = (context, state, attrs, field) => {
    const path = field.split(".")
    let parent, fieldPath
    switch (path[0]) {
        case "attrs": {
            parent = attrs
            fieldPath = path.slice(1).join(".")
            break
        }
        case "state": {
            parent = state
            //const t = Type.getType(parent, "map")
            //const fieldName = path[path.length - 1]
            fieldPath = path.slice(1).join(".")
            /*const fieldType = Type.getKeyType(fieldPath, parent, t)
            const value = Data.get(parent, fieldPath, { parentType: t })
            const typeName = Type.realType(fieldType)
            const [tagName, tag] = getElement(fieldType)
            return { field, fieldName, fieldType, value, typeName, tagName, tag }*/
            break
        }
        default: {
            parent = context
            //const t = Type.getType(parent, "map")
            //const fieldName = path[path.length - 1]
            fieldPath = field
            /*const fieldType = Type.getKeyType(fieldPath, parent, t)
            const value = Data.get(parent, fieldPath, { parentType: t })
            const typeName = Type.realType(fieldType)
            const [tagName, tag] = getElement(fieldType)
            return { field, fieldName, fieldType, value, typeName, tagName, tag }*/
        }
    }
    const t = Type.getType(parent, { is: "map", values: "exprValExt" })
    const fieldName = path[path.length - 1]
    const fieldType = Type.getKeyType(fieldPath, parent, t)
    const value = Data.get(parent, fieldPath, { parentType: t })
    const typeName = Type.realType(fieldType)
    const [tagName, tag] = getElement(fieldType)
    return { field, fieldName, fieldType, value, typeName, tagName, tag }
}

const Fields = ({ info, type, parent, field, state, dispatch, attrs, isContent }) => {
    const fields = React.useMemo(() => {
        const { context } = info
        const t = Type.getType(context, "map")
        switch (type) {
            case "_all_": {
                const parentType = Type.getKeyType(parent, context, t)
                return parentType?.[$keyOrder].map(key => {
                    const field = `${parent}.${key}`
                    return getField(context, state, attrs, field)
                })
            }
            case "_rest_": {
                const parentType = Type.getKeyType(parent, context, t)
                return parentType?.[$keyOrder]
                    .filter(key => parentType.keys[key]?.keyType === "dynamic")
                    .map(key => {
                        const field = `${parent}.${key}`
                        return getField(context, state, attrs, field)
                    })
            }
            default: {
                if (!field) return null
                return [getField(context, state, attrs, field)]
            }
        }
    }, [info, type, parent, field, state, attrs])
    //console.log("FIELDS", fields, type, parent, field)
    if (!fields) return null
    return fields.map((f, i) => (
        <Field
            key={i}
            info={info}
            state={state}
            dispatch={dispatch}
            attrs={attrs}
            fieldData={f}
            isContent={isContent}
        />
    ))
}
export default Fields
