import React from "react"
import useAttrs from "ui/hooks/useAttrs"
import LazyImage from "lib/ui/LazyImage"
import useSubscription from "lib/hooks/useSubscription"

import renderers from "ui/renderers"

const LogoWrap = attrs => {
    const domRef = React.useRef()
    const { info, state, dispatch } = attrs
    const { Link } = renderers
    const [node] = useSubscription("node")

    const htmlAttrs = useAttrs(attrs)
    return (
        <div ref={domRef} {...htmlAttrs} elem-logo-wrap="" className="logo-wrap">
            <div className="logo-inner">
                <Link
                    info={info}
                    state={state}
                    dispatch={dispatch}
                    id="logo"
                    to={node?.path === "/" ? "/about-us" : "/"}
                >
                    <LazyImage
                        src={{
                            name: "SCG-logo.svg",
                            url: "/upload/element/exprVal/2021/7/3/scg-logo-1.svg",
                        }}
                        alt="SCG-logo.svg"
                        inline={true}
                    />
                </Link>
                <h3>Excellence in Sports & Leisure</h3>
            </div>
        </div>
    )
}
export default LogoWrap
/*
{
  "context": {
    "subscriptions": [
      "node"
    ]
  },
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "className": "logo-wrap"
      }
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "logo-inner"
          }
        },
        "children": [
          {
            "data": {
              "name": "Link",
              "attr": {
                "id": "logo",
                "_e": {
                  "id": {
                    "is": "exprValExt"
                  },
                  "to": {
                    "1": {
                      "1": {
                        "_type": "op"
                      },
                      "_type": "op"
                    },
                    "is": "exprValExt",
                    "_type": "op"
                  }
                },
                "to": [
                  "if",
                  [
                    "=",
                    [
                      "f",
                      "node.path"
                    ],
                    "/"
                  ],
                  "/about-us",
                  "/"
                ]
              }
            },
            "children": [
              {
                "data": {
                  "_e": {
                    "name": {
                      "_type": "img"
                    }
                  },
                  "name": [
                    {
                      "name": "SCG-logo.svg",
                      "url": "/upload/element/exprVal/2021/7/3/scg-logo-1.svg"
                    }
                  ],
                  "attr": {
                    "inline": true,
                    "_e": {
                      "inline": {
                        "_type": "bool",
                        "is": "exprValExt"
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "data": {
              "name": "h3",
              "attr": {
                "content": "Excellence in Sports & Leisure"
              }
            }
          }
        ]
      }
    ]
  }
}
*/
