import React from "react"
import Entity from "lib/entity"

/*const setRec = (state, path, i, value) => ({
  ...(state ?? {}),
  [path[i]]: i < path.length - 1 ? setRec(state[path[i]], path, i + 1, value) : value,
  })*/
const reducer = (state, action) => {
    //console.log(state, action)
    switch (action.type) {
        case "CHANGE": {
            const ret = Entity.set(state, action.field, action.value, {
                entityInfo: Entity.getType(state, { is: "map", values: "exprVal" }),
            })
            //console.log(state, action, ret)
            return ret
            //return setRec(state, action.field.split("."), 0, action.value)
        }
        case "JOIN": {
            return {
                ...state,
                ...action.newState,
                _e: {
                    ...(state._e ?? {}),
                    ...(action.newState._e ?? {}),
                },
            }
        }
        default:
            return state
    }
}
//export default reducer
export const useLocalState = (initialState, state, dispatch) => {
    const [localState, localDispatch] = React.useReducer(reducer, initialState)
    const proxyDispatch = React.useCallback(
        action => {
            switch (action.type) {
                case "CHANGE": {
                    const key = action.field?.split(".")?.[0]
                    if (
                        Object.keys(initialState).includes(key) ||
                        Object.keys(initialState?._e ?? {}).includes(key) ||
                        !dispatch
                    )
                        return localDispatch(action)
                    console.log("NOT LOCAL DISPATCH", action, initialState, key)
                    return dispatch(action)
                }
                default:
                    return
            }
        },
        [initialState, dispatch]
    )
    return React.useMemo(
        () => [{ ...state, ...localState }, proxyDispatch],
        [state, localState, proxyDispatch]
    )
}
